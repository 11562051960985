import React, { FC } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ReactComponent as FiveStars } from '@assets/icons/five-stars.svg';
import { RichText, Link } from '@components/atoms';

interface Item {
  blurb: any;
  header: string;
  icon: {
    url: string;
    alt?: string;
  };
  link: {
    url: string;
  };
  link_call_to_action: string;
  image: {
    gatsbyImageData: IGatsbyImageData;
    alt?: string;
  };
}

interface Props {
  content: {
    items: Item[];
  };
}

const ShopReviewSlice: FC<Props> = ({ content }) => (
  <div className="flex justify-center w-full">
    <div className="lg:pt-4 lg:px-4 lg:pb-14 w-full max-w-screen-3xl">
      <div className="bg-gradient-to-b from-transparent to-grey-review lg:bg-none">
        <div className="px-8 pt-12 pb-10 lg:pb-16 lg:pt-16 flex justify-center">
          <h2 className="tracking-tighter leading-none opacity-20 uppercase font-trade-gothic text-56">
            What customers are saying
          </h2>
        </div>
        <div className="-mx-2 flex items-stretch flex-wrap lg:px-16 xl:px-32">
          {content.items.map((item) => (
            <div
              key={item.header}
              className="bg-grey-review mx-2 flex-1 hidden lg:block first:block"
            >
              <div className="flex flex-col h-full">
                <div className="flex-grow px-8 py-12">
                  <div className="flex items-start justify-end mb-4">
                    <img
                      className="w-20 h-auto opacity-50"
                      alt={item.icon.alt ? item.icon.alt : ''}
                      src={item.icon.url}
                    />
                  </div>
                  <FiveStars />

                  <h3 className="tracking-tighter text-28 font-trade-gothic uppercase opacity-80 mb-4">
                    {' '}
                    {item.header}{' '}
                  </h3>
                  <RichText text={item.blurb} />
                  <div>
                    <Link className="underline text-14" to={item.link.url}>
                      {item.link_call_to_action}
                    </Link>
                  </div>
                </div>
                <div>
                  <GatsbyImage
                    objectFit="contain"
                    image={item.image.gatsbyImageData}
                    alt={item.image.alt ? item.image.alt : ''}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default ShopReviewSlice;
