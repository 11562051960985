import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: #fff3cd;
  padding: 12px;
  margin-bottom: 24px;

  & svg {
    display: inline;
  }
`;

export const Content = styled.div`
  color: #654418;
  font-size: 13px;
  line-height: 24px;
  margin-left: 6px;
  font-family: 'Avenir roman';
  display: inline;

  & .warning-highlight {
    font-family: 'Avenir heavy';
  }
`;
