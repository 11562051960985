import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { WithWorkoutFragment } from '@backend';
import React, { FC } from 'react';
import { Link } from '@components/atoms';
import { MusicNotes, Play } from 'phosphor-react';
import take from 'lodash/take';

interface HeroSliceCardProps {
  url?: string | null;
  tagline?: string | null;
  imageGatsby?: IGatsbyImageData | null;
  imageSrc?: string | null;
  workout?: WithWorkoutFragment | null;
}

const HomeHero: FC<HeroSliceCardProps> = ({ url, imageGatsby, imageSrc, tagline, workout }) => {
  // prettier-ignore
  // eslint-disable-next-line no-underscore-dangle
  const video = workout?.assets?.items?.[0]?.__typename === 'Video' ? workout.assets.items[0] : null;
  const duration = video?.duration;
  const name = workout?.workoutName;
  const tracks = (workout?.tracks?.items?.map((t) => t?.music?.[0]?.musicTitle)?.filter(Boolean) ??
    []) as string[];
  const instructors = (workout?.instructorTeam?.filter(Boolean) ?? []) as string[];

  return (
    <Link to={url} className="block">
      <div className="relative w-full rounded-md overflow-hidden z-10 group">
        {imageGatsby && (
          <GatsbyImage image={imageGatsby} alt="" className="inset-0 h-full object-cover" />
        )}

        {imageSrc && <img src={imageSrc} alt="" className="inset-0 h-full object-cover" />}

        <div className="z-20 top-0 left-0 absolute w-full h-full flex flex-col items-start justify-end px-6 py-5 bg-gradient-to-b from-transparent to-black-gradient group-hover:from-black-gradient">
          <div className="flex flex-col items-start space-y-2">
            {tagline && (
              <h2 className="p-2 bg-grey bg-opacity-50 backdrop-blur-xl rounded text-white text-12 uppercase font-avenir-heavy leading-none tracking-wider">
                {tagline}
              </h2>
            )}

            {name && (
              <h3 className="text-white text-40 uppercase font-trade-gothic leading-tight tracking-tight">
                {name}
              </h3>
            )}

            {instructors.length > 0 && (
              <div className="hidden sm:block text-white text-12 font-avenir uppercase leading-none tracking-wider">
                {'WITH '}
                {take(instructors, 3).join(', ')}
                {instructors.length > 3 && ' AND OTHERS'}
              </div>
            )}
          </div>
        </div>

        <div className="z-20 absolute inset-0 flex flex-col justify-between opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-out p-6">
          {tracks.length > 0 ? (
            <div className="h-8">
              <div className="hidden sm:flex text-white text-12 items-center space-x-2">
                <MusicNotes weight="bold" className="mb-0.5" />
                <span>{take(tracks, 3).join(', ')}</span>
              </div>
            </div>
          ) : (
            <div className="h-8" />
          )}

          <div className="px-4 py-2 rounded-full bg-black bg-opacity-75 flex items-center self-center space-x-2 text-white">
            <Play weight="fill" />

            {duration && (
              <span className="text-white text-20 font-trade-gothic">
                {Math.round(duration / 60)} MIN
              </span>
            )}
          </div>

          <div className="h-8" />
        </div>
      </div>
    </Link>
  );
};

export default HomeHero;
