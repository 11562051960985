import HeaderButton from '@components/molecules/header/headerButton';
import { authContext, userContext } from '@context';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { Menu, Transition } from '@headlessui/react';
import React, { FC, Fragment, useContext } from 'react';
import styled from 'styled-components';
import { ReactComponent as DiamondIcon } from '@assets/icons/dropdown-diamond.svg';
import { ReactComponent as MyAccountIcon } from '@assets/icons/my-account.svg';
import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg';
import { ReactComponent as ReferIcon } from '@assets/icons/refer.svg';
import { ReactComponent as User } from '@assets/icons/new-user.svg';
import { navigate } from 'gatsby';
import classNames from 'classnames';
import { pushTracking, logoutTracking } from '@src/utils';
import { Event, Params } from '@src/type';
import { Link } from '@components/atoms';
import { TIERS, PARAM_VALUE } from '@constants';
import ROUTES from '../../../../../../src/constants/routes';

const DropdownItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    margin: 0px 6px;
  }
`;

const Divider = styled.hr`
  border-top: 1px solid #333333;
  margin: 8px 0;
`;

const ProfileDropdown: FC = () => {
  const { isAuthenticated, handleSignOut } = useContext(authContext);
  const { givenName, familyName, tier, email, vimeoUserId } = useContext(userContext);
  const { rtTierNavigation } = useFlags();

  if (!isAuthenticated) {
    return <HeaderButton onClick={() => navigate('/login')} title="Sign In" icon={<User />} />;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        title="Account"
        className="text-12 w-7 h-7 flex justify-center items-center hover:bg-background-medium bg-background-mlight text-grey-review hover:text-netural-100 rounded-full"
      >
        {givenName && familyName ? (givenName![0] + familyName![0])?.toUpperCase() : <User />}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="bg-background-dark shadow-lg border-grey-light origin-top-right absolute right-x-12 mt-5 w-44 rounded-md focus:outline-none z-10">
          <i className="bg-background-dark top-x-6 right-24 absolute inline-block transform rotate-r-135 p-2" />
          <div className="px-4 pt-5 pb-2">
            <p className="text-grey-review text-16 leading-5 font-avenir-heavy font-extrabold truncate">
              {givenName} {familyName}
            </p>
            <p className="text-grey-review text-12 leading-4 font-avenir truncate">{email}</p>
          </div>
          <Divider />
          <Menu.Item>
            {({ active }) => (
              <Link
                to={
                  process.env.GATSBY_RT_19_02_2024_ACCOUNT_OVERVIEW === 'true'
                    ? '/account'
                    : `${process.env.GATSBY_MAIN_LES_MILLS_URL}/customer/account`
                }
                className={classNames(
                  'px-4 w-full text-left py-2 flex justify-between space-x-2 font-extrabold font-avenir text-14',
                  {
                    'bg-background-dark text-grey-review': !active,
                    'bg-background-light text-netural-100': active,
                  }
                )}
                onClick={() => {
                  pushTracking({
                    event: Event.NAV_SELECT,
                    [Params.MENU_CATEGORY_1]: 'Account',
                    [Params.MENU_CATEGORY_2]: 'My Account',
                    [Params.MENU_TYPE]: PARAM_VALUE.MENU_TYPE_HEADER,
                  });
                }}
              >
                <DropdownItem>
                  <MyAccountIcon />
                  <span>My Account</span>
                </DropdownItem>
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link
                style={
                  rtTierNavigation && tier === TIERS.BASE
                    ? { background: '#F7E7C8', color: 'black' }
                    : null
                }
                to={rtTierNavigation && tier === TIERS.BASE ? ROUTES().SUBSCRIPTION : '/refer/'}
                className={classNames(
                  'px-4 w-full text-left py-2 flex justify-between space-x-2 font-extrabold font-avenir text-14',
                  {
                    'bg-background-dark text-grey-review': !active,
                    'bg-background-light text-netural-100': active,
                  }
                )}
                onClick={() => {
                  pushTracking({
                    event: Event.NAV_SELECT,
                    [Params.MENU_CATEGORY_1]: 'Account',
                    [Params.MENU_CATEGORY_2]:
                      rtTierNavigation && tier === TIERS.BASE ? 'Go Premium' : 'Refer a Friend',
                    [Params.MENU_TYPE]: PARAM_VALUE.MENU_TYPE_HEADER,
                  });
                }}
              >
                {rtTierNavigation && tier === TIERS.BASE ? (
                  <DropdownItem>
                    <DiamondIcon />
                    <span>Go Premium</span>
                  </DropdownItem>
                ) : (
                  <DropdownItem>
                    <ReferIcon />
                    <span>Refer a Friend</span>
                  </DropdownItem>
                )}
              </Link>
            )}
          </Menu.Item>
          <Divider />
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => {
                  logoutTracking(vimeoUserId);
                  handleSignOut?.();
                }}
                type="button"
                className={classNames(
                  'rounded-b-md px-4 w-full text-left py-2 pb-4 flex justify-between space-x-2 font-extrabold font-avenir text-14',
                  {
                    'bg-background-dark text-grey-review': !active,
                    'bg-background-light text-netural-100': active,
                  }
                )}
              >
                <DropdownItem>
                  <LogoutIcon />
                  <span>Log Out</span>
                </DropdownItem>
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileDropdown;
