import React, { FC } from 'react';
// import Barbell from '@assets/equipment/barbell.svg';
import { useAssetByExternalIdQuery, useWorkoutByNameQuery } from '@backend';
import { Skeleton, Link } from '@components/atoms';
import { MusicNotes, Play } from 'phosphor-react';

// TODO: Equipment icons.
// const EQUIPMENT = { barbell: Barbell };

interface Props {
  workoutId: string;
  workoutVideoUrl: string;
}

const WorkoutItem: FC<Props> = ({ workoutId, workoutVideoUrl }) => {
  const { data: assetData } = useAssetByExternalIdQuery({ variables: { externalId: workoutId } });
  const { data: workoutData } = useWorkoutByNameQuery({
    variables: {
      product: assetData?.assetByExternalId?.product ?? '',
      workoutName: assetData?.assetByExternalId?.workoutName ?? '',
    },
    skip: !assetData?.assetByExternalId?.product || !assetData?.assetByExternalId?.workoutName,
  });

  const workout = workoutData?.workoutByName?.items?.[0];

  if (!workout) {
    return <Skeleton className="rounded-md overflow-hidden w-full h-48 bg-white" />;
  }

  // eslint-disable-next-line no-underscore-dangle
  const video = workout.assets?.items?.[0]?.__typename === 'Video' ? workout.assets.items[0] : null;

  return (
    <Link to={workoutVideoUrl}>
      <div className="relative rounded-md overflow-hidden w-full h-48 group">
        {workout.imageWide && (
          <img
            src={workout.imageWide}
            alt=""
            className="absolute inset-0 h-full w-full object-cover"
          />
        )}

        <div className="z-20 relative h-full flex flex-col items-start justify-end p-4 bg-gradient-to-b from-transparent to-black-gradient group-hover:from-black-gradient">
          <div className="">
            <h3 className="text-white text-20 leading-6 uppercase font-trade-gothic">
              {workout?.workoutName}
            </h3>
            <div className="flex items-center space-x-3">
              {/* <div className="flex space-x-2 mb-1">
                {equipment.map((e) => (
                  <img key={e} src={EQUIPMENT[e]} alt={e} />
                ))}
              </div> */}
              <div className="text-white opacity-75 text-12 align-middle flex items-center space-x-2">
                {workout?.instructorTeam?.[0]}
              </div>
            </div>
          </div>
        </div>

        <div className="z-20 absolute inset-0 flex flex-col justify-between opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-out p-4">
          <div className="text-white text-12 flex items-center space-x-2">
            <MusicNotes weight="bold" className="mb-0.5" />
            <span>{workout.tracks?.items?.[0]?.music?.[0]?.musicTitle}</span>
          </div>

          <div className="px-3 py-2 rounded-full bg-black bg-opacity-75 flex items-center self-center space-x-2 text-white">
            <Play weight="fill" />

            {video?.duration && (
              <span className="text-white text-18 font-trade-gothic">
                {Math.round(video.duration / 60)} MIN
              </span>
            )}
          </div>

          <div className="h-8" />
        </div>
      </div>
    </Link>
  );
};

export default WorkoutItem;
