import React from 'react';
import { Video, RichText } from '@components/atoms';

const LearnVideoSlice = ({ content }) => (
  <div className="pt-4 lg:pt-6 pb-8 lg:pb-12 -mx-4 lg:-mx-12">
    <div className="flex flex-wrap lg:flex-row-reverse items-stretch">
      <div className="bg-grey text-white w-full lg:w-1/3 p-8">
        {content.primary.video_title.text && (
          <h2 className="font-trade-gothic text-32 uppercase leading-none mb-4">
            {content.primary.video_title.text}
          </h2>
        )}

        <RichText text={content.primary.description} />
      </div>
      <div className="w-full h-full lg:w-2/3">
        <Video vimeoId={content.primary.vimeo_id} />
      </div>
    </div>
  </div>
);

export default LearnVideoSlice;
