import styled from 'styled-components';

export const ContentWrapper = styled.div`
  position: relative;
  padding: 27px 25px;
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Avenir roman';
  font-weight: 400;
`;
export const Title = styled.p`
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: 800;
  font-family: 'Avenir medium';
`;

export const BtnsWrapper = styled.div`
  text-align: right;
  margin-top: 15px;

  & .leave-page-btn {
    color: ${(props) => props.theme.colors.darkRed};
  }
`;

export const BtnText = styled.span`
  margin-left: 32px;
  font-weight: 800;
  cursor: pointer;
  font-family: 'Avenir medium';
  text-transform: uppercase;
`;
