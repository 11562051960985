import styled from 'styled-components';

import { breakpoint } from '../../../themes/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const Title = styled.h2`
  margin-bottom: 24px;
  font-family: 'Avenir medium';
  font-size: 24px;
  line-height: 40px;
  font-weight: 800;
`;

export const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    margin-bottom: 6px;
    margin-top: 0;
  }

  ${breakpoint('lg')`
    flex-direction: row;
    align-items: stretch;
    gap: 16px;
    & > div {
      margin-top: 0;
    }
  `}
`;
