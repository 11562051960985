import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as WorkoutHistoryPlaceholder } from '@assets/icons/workout-history-placholder.svg';
import { COLORS } from '@themes';

interface Props {
  width?: string;
  height?: string;
  text?: string;
}

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  background: 'linear-gradient(180deg, ${COLORS.whiteOpacity005} 0%, ${COLORS.white} 100%)';
  border-radius: 16px;
  padding-top: 15px;

  svg {
    width: 293px;
    height: auto;
    padding-bottom: 24px;
  }
`;

const PlaceholderText = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-family: 'Avenir roman';
  color: ${COLORS.lightishGrey};
`;

const SlicePlaceholder: FC<Props> = ({ width, height, text }) => (
  <PlaceholderWrapper width={width} height={height}>
    <WorkoutHistoryPlaceholder />
    {text && <PlaceholderText>{text}</PlaceholderText>}
  </PlaceholderWrapper>
);

export default SlicePlaceholder;
