import styled from 'styled-components';
import { themes } from '@lesmills-international/components';

export const DialogButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const CancellationSuccessTitle = styled.h3`
  font-family: 'Avenir heavy';
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentPrimary};
  margin-bottom: 32px;
`;

export const CancellationSuccessDescription = styled.p`
  font-family: 'Avenir medium';
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentPrimary};
`;

export const RestartSubscriptionDescription = styled.p`
  font-family: 'Avenir medium';
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentSecondary};
`;

export const ResubscribeTextButton = styled.button`
  font-family: 'Avenir medium';
  text-decoration: underline;
  cursor: pointer;
`;

export const DialogTextButton = styled.button`
  font-family: 'Avenir medium';
  text-decoration: underline;
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentPrimary};
  cursor: pointer;
  display: flex;
  align-items: center;
`;
