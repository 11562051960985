import { ArticleItemHorizontal } from '@components/molecules';
import { RichText } from 'prismic-reactjs';
import React, { FC } from 'react';

interface Props {
  content: Queries.ContrastArticlesSectionFragment;
}

const ContrastArticlesSectionSlice: FC<Props> = ({ content }) => (
  <div className="bg-grey">
    <div className="max-w-3xl mx-auto px-4 py-8">
      {content.primary?.contrast_heading && (
        <h2 className="text-white font-trade-gothic text-32 uppercase tracking-tighter leading-none">
          {content.primary.contrast_heading.text}
        </h2>
      )}

      {content.primary?.contrast_subheading && (
        <div className="text-white opacity-75 text-13 leading-relaxed mt-2">
          <RichText render={content.primary.contrast_subheading.richText} />
        </div>
      )}

      <div className="grid lg:grid-cols-2 gap-4 mt-5">
        {content.items?.map(
          (article) =>
            article?.contrast_article?.document && (
              <ArticleItemHorizontal
                key={article.contrast_article.document.id}
                title={article.contrast_article.document.data?.title?.text}
                lastPublicationDate={article.contrast_article.document.first_publication_date}
                image={article.contrast_article.document.data?.display_image}
                url={article.contrast_article.document.url}
                hasDarkBackground
                small
              />
            )
        )}
      </div>
    </div>
  </div>
);

export default ContrastArticlesSectionSlice;
