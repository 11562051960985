/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Accordion as MUIAccordion } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import styled, { css } from 'styled-components';
import COLORS from '../../../themes/colors';

interface MenuItem {
  title: string;
  icon?: any; // TODO define type correctly
  content: React.ReactNode;
  onExpand?: () => void;
  onClose?: () => void;
}

interface Props {
  items: MenuItem[];
}

const Wrapper = styled(MUIAccordion)(() => ({
  fontFamily: 'Avenir medium',
  fontSize: '16px',
  border: `1px solid ${COLORS.borderGrey}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.MuiPaper-root.Mui-expanded': {
    margin: 0,
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Summary = styled(AccordionSummary)((_props) => ({
  flexDirection: 'row-reverse',

  '& .MuiAccordionSummary-content.MuiAccordionSummary-content': {
    marginLeft: '16px',
    '&.Mui-expanded': {
      marginLeft: '16px',
    },
  },

  '&.MuiAccordionSummary-root.Mui-expanded': {
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginTop: '-15px',
  },

  '&:hover': {
    backgroundColor: COLORS.whiteSmoke,
  },
}));

const Details = styled(AccordionDetails)(() => ({
  padding: '8px 16px 0px 16px',
}));

const TitleIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: '8px';
`;

const RadioButton = styled.div<{ isActive: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #888888;

  ${({ isActive }) =>
    isActive &&
    css`
      border: 4px solid ${COLORS.accentGreen};
      box-sizing: border-box;
    `}
`;

const PaymentMenu: React.FC<Props> = ({ items }) => {
  const [isActive, setIsActive] = useState<number | false>(false);

  const toggleAccordion =
    ({
      index,
      expandedCallBack,
      closedCallBack,
    }: {
      index: number;
      expandedCallBack?: () => void;
      closedCallBack?: () => void;
    }) =>
    (_event: React.SyntheticEvent, expanded: boolean) => {
      setIsActive(expanded ? index : false);

      if (expanded && expandedCallBack) {
        expandedCallBack();
      }

      if (!expanded && closedCallBack) {
        closedCallBack();
      }
    };

  return (
    <div>
      {items.map(({ title, icon, content, onExpand, onClose }, index) => {
        const expanded = isActive === index;

        return (
          <Wrapper
            expanded={expanded}
            elevation={0}
            square
            onChange={toggleAccordion({
              index,
              expandedCallBack: onExpand,
              closedCallBack: onClose,
            })}
            key={`accordion_wrapper_${index}`}
          >
            <Summary
              aria-controls={`payment_content_${index}`}
              expandIcon={<RadioButton isActive={expanded} />}
              id={`payment_header_${index}`}
            >
              <TitleIconWrapper>
                <div>{title}</div>
                <div>
                  <GatsbyImage
                    id="payment-method-logo"
                    image={icon?.gatsbyImageData}
                    alt={icon?.alt}
                  />
                </div>
              </TitleIconWrapper>
            </Summary>
            {expanded && <Details>{content}</Details>}
          </Wrapper>
        );
      })}
    </div>
  );
};

export default PaymentMenu;
