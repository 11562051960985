import React from 'react';
import { Link } from '@components/atoms';

// eslint-disable-next-line object-curly-newline
const Button = ({ size, variant, children, href, isBlock, ...rest }) => {
  // Button Base Styles
  const styles = [
    'cursor-pointer',
    'uppercase',
    'text-white',
    'font-trade-gothic',
    'focus:outline-none',
    'disabled:opacity-70',
    'disabled:cursor-wait',
  ];

  // Add button size classes
  if (size === 'small') {
    styles.push('');
  } else if (size === 'large') {
    // TBD styles
  } else {
    styles.push('text-14');
    styles.push('py-4');
  }

  // Make button take up the full width of its container
  if (isBlock) {
    styles.push('w-full');
    styles.push('block');
    styles.push('text-center');
  }
  //

  // Variants
  if (variant === 'secondary') {
    styles.push('bg-black');
    // styles.push('hover:bg-grey');
  } else {
    styles.push('bg-orange');
    styles.push('bg-orange');
    styles.push('hover:bg-orange-light');
    styles.push('focus:bg-orange-dark');
  }

  // Determine whether to return a button or a href styled as a button
  if (href) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Link to={href} className={styles.join(' ')} {...rest}>
        {children}
      </Link>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button type="submit" className={styles.join(' ')} {...rest}>
      {children}
    </button>
  );
};

export default Button;
