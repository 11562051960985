import React, { FC } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { RichText } from 'prismic-reactjs';
import { graphql, PageProps } from 'gatsby';

import { Layout, withPrivateRoute } from '@components/molecules';
import { Theme } from '@components/molecules/layout';
import { Link } from '@components/atoms';
import { GatsbyImage } from 'gatsby-plugin-image';
import SliceZone from '@components/sliceZone';

interface PageContextProps {
  lang: string;
}

const ArticleHub: FC<PageProps<Queries.GetArticleHubDataQuery, PageContextProps>> = ({
  data,
  pageContext,
}) => {
  const pageData = data.prismicArticleHub?.data;
  const metaData = {
    title: pageData?.page_title?.text,
    description: pageData?.page_description?.text,
    imageSrc: pageData?.page_image?.gatsbyImageData?.images?.fallback?.src,
    imageAlt: pageData?.page_image?.alt,
  };

  return (
    <Layout metaData={metaData} theme={Theme.White}>
      <div className="p-4">
        <div className="relative">
          {pageData?.page_header_image?.gatsbyImageData && (
            <GatsbyImage
              image={pageData.page_header_image.gatsbyImageData}
              alt={pageData.page_header_image.alt ?? ''}
              className="w-full h-56 object-cover"
            />
          )}

          <div className="absolute inset-0 bg-grey bg-opacity-50 flex flex-col items-center justify-center text-center p-6">
            {pageData?.page_heading && (
              <h1 className="text-white font-trade-gothic uppercase text-40 leading-loose sm:text-80 sm:leading-tight tracking-tight">
                {pageData.page_heading.text}
              </h1>
            )}

            {pageData?.page_subheading && (
              <div className="text-white opacity-75 text-13 leading-relaxed max-w-3xl">
                <RichText render={pageData.page_subheading.richText} />
              </div>
            )}
          </div>
        </div>
      </div>

      <SliceZone slices={pageData?.body} />

      <div className="max-w-3xl mx-auto px-4">
        <div className="flex items-center justify-between pb-8">
          <div className="hidden md:block bg-black opacity-10 h-px w-full mr-6" />

          <Link
            to={
              pageContext.lang === 'en-nz' ? '/fit-planet/1/' : `/${pageContext.lang}/fit-planet/1/`
            }
            className="flex-shrink-0 w-full md:w-auto text-center bg-grey text-white py-4 px-16 text-12 font-avenir-heavy tracking-wider leading-tight uppercase hover:bg-opacity-75 focus:bg-opacity-75"
          >
            View All Articles
          </Link>

          <div className="hidden md:block bg-black opacity-10 h-px w-full ml-6" />
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicPreview(withPrivateRoute(ArticleHub));

export const query = graphql`
  query GetArticleHubData($lang: String!) {
    prismicArticleHub(lang: { eq: $lang }) {
      _previewable
      id
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        page_image {
          gatsbyImageData(width: 1200)
          alt
        }
        page_heading {
          text
        }
        page_subheading {
          richText
        }
        page_header_image {
          gatsbyImageData
          alt
        }
        body {
          ... on PrismicArticleHubDataBodyArticlesSection {
            ...ArticlesSection
          }
          ... on PrismicArticleHubDataBodyContrastArticlesSection {
            ...ContrastArticlesSection
          }
        }
      }
    }
  }

  fragment ArticlesSection on PrismicArticleHubDataBodyArticlesSection {
    id
    slice_type
    primary {
      heading {
        text
      }
    }
    items {
      article {
        document {
          ... on PrismicLearnPage {
            ...ArticleHubLearnPage
          }
        }
      }
    }
  }

  fragment ContrastArticlesSection on PrismicArticleHubDataBodyContrastArticlesSection {
    id
    slice_type
    primary {
      contrast_heading {
        text
      }
      contrast_subheading {
        richText
      }
    }
    items {
      contrast_article {
        document {
          ... on PrismicLearnPage {
            ...ArticleHubLearnPage
          }
        }
      }
    }
  }

  fragment ArticleHubLearnPage on PrismicLearnPage {
    id
    url
    data {
      display_image {
        alt
        gatsbyImageData
      }
      meta_description
      title {
        text
      }
    }
    first_publication_date
  }
`;
