import React, { FC } from 'react';

interface Props {
  title?: string;
  description?: any;
  onSkip?: () => void;
}

const HomeSection: FC<Props> = ({ title, description, children, onSkip }) => (
  <div className="relative pt-6 border-t border-grey-new-light">
    {onSkip && (
      <div className="absolute top-0 left-0 w-full transform -translate-y-1/2 transition-opacity duration-100 delay-200 opacity-0 hover:opacity-100 flex justify-center md:justify-end">
        <div className="bg-grey-new-lighterer pl-2 pr-2 md:pr-0">
          <button
            onClick={() => onSkip()}
            type="button"
            className="text-grey-new text-12 uppercase"
          >
            Skip Series
          </button>
        </div>
      </div>
    )}

    {description ? (
      <div className="grid grid-cols-4 gap-6">
        <div className="col-span-4 md:col-span-1 text-center md:text-left flex flex-col justify-center space-y-3">
          <h2 className="text-grey text-40 leading-none uppercase font-trade-gothic">{title}</h2>
          <div className="text-grey text-12 leading-4 tracking-wider font-avenir">
            {description}
          </div>
        </div>
        <div className="col-span-4 md:col-span-3">{children}</div>
      </div>
    ) : (
      <>
        {title && (
          <h2 className="text-grey text-12 leading-4 tracking-wider uppercase font-avenir-heavy mb-5">
            {title}
          </h2>
        )}

        {children}
      </>
    )}
  </div>
);

export default HomeSection;
