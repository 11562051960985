/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { ReactComponent as BackLogo } from '@assets/icons/back.svg';
import { BackgroundImage, Link } from '@components/atoms';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import ProgressBar from '../progressBar';

interface PlanHeroHeaderProps {
  heroImage: {
    gatsbyImageData: IGatsbyImageData;
  };
  name: string;
  description: string;
  tags: string[];
  ritualType: string;
  weeks: number;
  currentWeek: number;
  isInProgress: boolean;
  onButtonClick: () => void;
}

const PlanHeroHeader = ({
  heroImage,
  name,
  tags,
  description,
  ritualType,
  weeks,
  currentWeek,
  isInProgress,
  onButtonClick,
}: PlanHeroHeaderProps) => (
  <BackgroundImage image={heroImage}>
    <div className={`${isInProgress ? 'h-96 pb-14' : 'h-80'} m-4 rounded-md relative`}>
      <div className="flex flex-col md:flex-row mx-auto w-5/6 md:w-3/4 pt-5 md:pt-10 h-full">
        <div className="flex flex-col flex-grow">
          <div className="flex-grow">
            <Link to="/plans/">
              <div className="flex items-center text-white ">
                <BackLogo />
                <div className="ml-2">BACK</div>
              </div>
            </Link>
          </div>
          <div className="w-2/3 break-words text-white font-trade-gothic text-40 md:text-62 font-bold uppercase tracking-tight leading-none mt-1">
            {name}
          </div>
          {!isInProgress && (
            <div className="flex">
              {tags?.map((item) => (
                <div className="pt-1.5 pb-1 px-2.5 bg-black bg-opacity-50 rounded-md mr-1 text-white uppercase font-avenir text-10 mt-2">
                  {item}
                </div>
              ))}
            </div>
          )}
          <div className="mt-3 mb-6 text-white font-avenir text-16 leading-5 overflow-hidden overflow-ellipsis hidden md:block">
            {description}
          </div>
        </div>
        {!isInProgress && (
          <div
            className="font-avenir-heavy font-extrabold text-12 bg-white md:w-44 h-11 md:self-end flex justify-center items-center rounded uppercase md:mt-0 mt-2 mb-7 md:ml-9 cursor-pointer flex-shrink-0"
            onClick={onButtonClick}
          >
            JOIN {ritualType}
          </div>
        )}
      </div>
      {isInProgress && (
        <div className="absolute w-full bottom-0 grid grid-rows-2 grid-cols-2 justify-items-center md:flex md:justify-evenly items-center h-20 md:h-14 bg-black bg-opacity-40">
          <div className="font-avenir text-14 text-green-progress font-normal">
            Your Active Plan
          </div>
          <div className=" row-start-1 col-span-2">
            <ProgressBar weeks={weeks} currentWeek={currentWeek} />
          </div>
          <div className="font-avenir text-14 text-green-progress font-normal">
            Week {currentWeek} of {weeks}
          </div>
        </div>
      )}
    </div>
  </BackgroundImage>
);

export default PlanHeroHeader;
