import React, { FC } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { formatDate } from '@services';

interface Props {
  title?: string;
  blurb?: string;
  url: string;
  image?: {
    gatsbyImageData?: IGatsbyImageData;
    alt?: string;
  };
  lastPublicationDate: string;
}

const ArticleListItem: FC<Props> = ({ title, blurb, url, image, lastPublicationDate }) => (
  <Link to={url} className="flex hover:shadow-bottom">
    <div className="">
      {image?.gatsbyImageData && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ?? ''}
          className="object-cover w-40 h-40"
        />
      )}
    </div>
    <div className="bg-white px-6 py-5 flex-grow flex flex-col">
      {title && (
        <h2 className="text-grey font-trade-gothic leading-tight text-20 uppercase mb-3">
          {title}
        </h2>
      )}

      <div className="flex-grow">
        {blurb && (
          <p className="font-avenir text-14 text-black opacity-80 leading-snug line-clamp-2 mb-6">
            {blurb}
          </p>
        )}
      </div>

      <div className="flex items-center">
        <div className="font-avenir text-14 leading-tight text-grey opacity-75">
          Posted on {formatDate(lastPublicationDate)}
        </div>
      </div>
    </div>
  </Link>
);

export default ArticleListItem;
