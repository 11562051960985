import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import { Layout, LearnHeader, withPrivateRoute } from '@components/molecules';
import { Theme } from '@components/molecules/layout';
import SliceZone from '@components/sliceZone';
import { formatDate } from '@services';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Transition } from '@headlessui/react';
import { useOnScreen } from '@hooks';

import SharingButtons from './sharingButtons';

interface PageContextProps {
  lang: string;
}

const ArticlePage: FC<PageProps<Queries.GetArticlePageDataQuery, PageContextProps>> = ({
  data,
  pageContext,
}) => {
  const [topBylineRef, topBylineIsVisible] = useOnScreen<HTMLDivElement>(true);
  const [bottomBylineRef, botomBylineIsVisible] = useOnScreen<HTMLDivElement>(true);

  const content = data.prismicLearnPage?.data;
  const lastPublicationDate = data.prismicLearnPage?.first_publication_date;

  if (!content || !lastPublicationDate) return null;

  const url = [data.site?.siteMetadata?.siteUrl, data.prismicLearnPage.url].join('');
  const metaData = {
    title: content.meta_title,
    description: content.meta_description,
    imageSrc: content.display_image?.gatsbyImageData?.images?.fallback.src,
    imageAlt: content.display_image?.alt,
    type: 'article',
  };

  return (
    <Layout metaData={metaData} theme={Theme.White}>
      <LearnHeader
        lang={pageContext.lang}
        title={data.prismicArticleHub?.data?.page_heading?.text}
        image={data.prismicArticleHub?.data?.page_header_image}
      />

      <Transition
        show={!topBylineIsVisible && !botomBylineIsVisible}
        enter="transition-opacity ease-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="hidden xl:block fixed left-1/2 top-1/2 z-10"
        style={{
          transform: 'translateX(-38rem) translateY(-50%)',
        }}
        as="aside"
        aria-hidden
      >
        <div className="w-48 flex flex-col items-end text-right">
          <span className="font-trade-gothic text-24 leading-none tracking-tighter uppercase text-black mb-2">
            {content.title?.text}
          </span>

          <div className="w-full h-px bg-grey-new-light mb-3" />

          <SharingButtons url={url} />
        </div>
      </Transition>

      <article className="max-w-2xl mx-auto px-4 py-4 lg:py-8 space-y-4 lg:space-y-6">
        <h1 className="uppercase font-trade-gothic text-40 lg:text-56 leading-none tracking-tight">
          {content.title?.text}
        </h1>

        <aside ref={topBylineRef} className="flex items-center justify-between space-x-3">
          <div className="flex items-center">
            <div className="text-14 leading-relaxed text-black">
              {formatDate(lastPublicationDate)}
            </div>
          </div>

          <SharingButtons url={url} />
        </aside>

        <main className="">
          <SliceZone slices={content.body} />
        </main>

        <aside ref={bottomBylineRef} className="flex items-center justify-between space-x-3">
          <SharingButtons url={url} />
        </aside>
      </article>
    </Layout>
  );
};

export default withPrismicPreview(withPrivateRoute(ArticlePage));

export const query = graphql`
  query GetArticlePageData($lang: String!, $uid: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    prismicArticleHub {
      _previewable
      data {
        page_heading {
          text
        }
        page_header_image {
          gatsbyImageData(width: 1200)
          alt
        }
      }
    }

    prismicLearnPage(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      first_publication_date
      url
      data {
        meta_title
        meta_description
        author
        display_image {
          gatsbyImageData
          alt
        }
        author_thumbnail {
          gatsbyImageData
          alt
        }
        title {
          text
        }
        body {
          ... on PrismicLearnPageDataBodyLearnImageSlice {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
                alt
              }
              link {
                url
              }
            }
          }
          ... on PrismicLearnPageDataBodyLearnVideoSlice {
            id
            slice_type
            primary {
              vimeo_id
              video_title {
                text
              }
              description {
                html
                richText
              }
            }
          }
          ... on PrismicLearnPageDataBodyLearnRichTextSlice {
            id
            slice_type
            primary {
              text {
                richText
                html
              }
            }
          }
          ... on PrismicLearnPageDataBodyQuote {
            id
            slice_type
            primary {
              quote_text {
                richText
                text
                html
              }
              quote_author {
                text
              }
            }
          }
        }
      }
    }
  }
`;
