import { ArticleItem, ArticleItemHorizontal } from '@components/molecules';
import classNames from 'classnames';
import React, { FC } from 'react';

interface Props {
  content: Queries.ArticlesSectionFragment;
  previousSliceType?: string | null;
}

const ArticlesSectionSlice: FC<Props> = ({ content, previousSliceType }) => (
  <div className="max-w-3xl mx-auto px-4">
    <div
      className={classNames(
        'pt-6 pb-8',
        previousSliceType === 'articles_section' && 'border-t border-grey-new-light'
      )}
    >
      {content.primary?.heading && (
        <h2 className="text-grey font-avenir-heavy uppercase text-12 tracking-wider leading-tight mb-5">
          {content.primary.heading.text}
        </h2>
      )}

      {content.items?.length === 1 && content.items?.[0]?.article?.document ? (
        <ArticleItemHorizontal
          title={content.items?.[0]?.article?.document.data?.title?.text}
          image={content.items?.[0]?.article?.document.data?.display_image}
          blurb={content.items?.[0]?.article?.document.data?.meta_description}
          lastPublicationDate={content.items?.[0]?.article?.document.first_publication_date}
          url={content.items?.[0]?.article?.document.url}
        />
      ) : content.items?.length === 2 ? (
        <div className="grid lg:grid-cols-2 gap-4">
          {content.items?.map(
            (item) =>
              item?.article?.document && (
                <ArticleItem
                  key={item?.article?.document.id}
                  title={item.article.document.data?.title?.text}
                  image={item.article.document.data?.display_image}
                  lastPublicationDate={item.article.document.first_publication_date}
                  url={item.article.document.url}
                />
              )
          )}
        </div>
      ) : (
        <div className="grid sm:grid-cols-3 gap-4">
          {content.items?.map(
            (item) =>
              item?.article?.document && (
                <ArticleItem
                  key={item?.article?.document.id}
                  title={item.article.document.data?.title?.text}
                  url={item.article.document.url}
                  image={item.article.document.data?.display_image}
                  lastPublicationDate={item.article.document.first_publication_date}
                  small
                />
              )
          )}
        </div>
      )}
    </div>
  </div>
);

export default ArticlesSectionSlice;
