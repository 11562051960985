import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { breakpoint } from '@themes';

export const AlbumSliceWrapper = styled.div`
  width: auto;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-left: 16px;
  margin-right: 16px;

  ${breakpoint('sm')`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${breakpoint('lg')`
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: 80px;
    margin-right: 80px;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h4`
  color: ${(props) => props.theme.colors.lightishGrey};
  font-size: 18px;
  line-height: 25px;
  font-family: 'Avenir heavy';
  margin-bottom: 16px;

  ${breakpoint('lg')`
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 24px;
  `}
`;

export const ViewAllLink = styled.a`
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;

  ${breakpoint('lg')`
    font-size: 18px;
    line-height: 21px;
  `}
`;
