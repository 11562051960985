/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { CustomDatePicker } from '@components/atoms';
import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { StaticImage } from 'gatsby-plugin-image';

interface SignUpPackProps {
  ritualName: string;
  onCancel: () => void;
  onConfirm: (date: Date) => void;
}

const PlanSignUpPack = ({ ritualName, onConfirm, onCancel }: SignUpPackProps) => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);

  return (
    <div style={{ width: '800px', height: '435px' }} className="bg-white flex">
      <StaticImage src="../../../assets/new-placeholder.jpg" alt="" />
      <div className="flex flex-col w-full items-center justify-center gap-6 my-5">
        <div className="self-end cursor-pointer mr-5 mb-8" onClick={onCancel}>
          <CloseIcon />
        </div>
        <div className="font-trade-gothic font-bold text-40 leading-none tracking-tighter uppercase">
          SIGN UP - {ritualName}
        </div>
        <div className="text-center font-avenir text-14 leading-5 mx-5">
          Enter your starting date to receive your Ritual pack
        </div>
        <div className="w-80">
          <CustomDatePicker
            placeholderText="Start Date"
            selected={startDate}
            minDate={new Date()}
            onChange={(date: Date) => setStartDate(date)}
          />
        </div>
        <div className="flex items-center gap-4 mx-5">
          <input type="checkbox" onChange={(e) => setIsAgreed(e.currentTarget.checked)} />
          <div className="font-avenir text-12">
            Yes, please email my plan pack & weekly motivation and support.
          </div>
        </div>
        <div
          className={`w-80 h-12 bg-black rounded flex justify-center items-center text-white text-12 font-avenir-heavy tracking-wider uppercase ${
            isAgreed ? 'cursor-pointer' : 'cursor-not-allowed'
          } `}
          onClick={() => {
            if (startDate && isAgreed) onConfirm(startDate);
          }}
        >
          GET YOUR PACK
        </div>
      </div>
    </div>
  );
};

export default PlanSignUpPack;
