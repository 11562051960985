import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { breakpoint } from '@themes';

export const VideoSlimWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  overflow-x: hidden;
  background: none;

  ${breakpoint('md')`
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: row;
    gap: 32px;
  `}
`;

export const TitleWrapper = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: space-between;

  ${breakpoint('sm')`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${breakpoint('lg')`
    margin-left: 80px;
    margin-right: 80px;
  `}
`;

export const Title = styled.h4`
  color: ${(props) => props.theme.colors.lightishGrey};
  font-size: 18px;
  line-height: 25px;
  font-family: 'Avenir heavy';
  padding-left: 12px;

  ${breakpoint('lg')`
    font-size: 24px;
    line-height: 33px;
  `}
`;

export const ViewAllLink = styled.a`
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;

  ${breakpoint('lg')`
    font-size: 18px;
    line-height: 21px;
  `}
`;

export const WorkoutSlimComponentWrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;

  display: flex;
  flex-direction: column;

  ${breakpoint('md')`
    padding-top: 0px;
    padding-bottom: 0px;
  `}
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 16px;
  margin-top: 16px;
  margin-left: 12px;

  ${breakpoint('md')`
    height: 100%;
    margin-top: 24px;
  `}

  svg {
    width: 293px;
    height: auto;
    padding-bottom: 48px;

    ${breakpoint('md')`
      width: 392px;
      height: auto;
    `}
  }
`;

export const PlaceholderText = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-family: 'Avenir roman';
  color: ${(props) => props.theme.colors.lightishGrey};
`;

export const VideoCardSlimWrapper = styled.div`
  margin-top: 4px;

  ${breakpoint('md')`
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
    row-gap: 16px;
  `}

  ${breakpoint('sm')`
    padding-left: 32px;
    padding-right: 32px;
  `}

  ${breakpoint('lg')`
    padding-left: 80px;
    padding-right: 80px;
  `}
`;
