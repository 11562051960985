import React, { FC } from 'react';
import classNames from 'classnames';

interface Props {
  firstName: string;
  lastName: string;
  avatarUrl: string | null;
  size?: 'small' | 'large';
}

const Avatar: FC<Props> = ({ firstName, lastName, avatarUrl, size }) => {
  if (avatarUrl) {
    return (
      <img
        src={avatarUrl}
        alt="Avatar"
        className={classNames(
          'object-contain rounded-full flex-shrink-0',
          size === 'large' ? 'w-16 h-16' : 'w-6 h-6'
        )}
      />
    );
  }

  const initials = [firstName, lastName]
    .map((w) => w[0])
    .map((w) => w.toUpperCase())
    .join('');

  return (
    <div
      className={classNames(
        'rounded-full bg-grey flex-shrink-0 flex justify-center items-center',
        size === 'large' ? 'w-16 h-16' : 'w-6 h-6'
      )}
    >
      <div
        className={classNames(
          'text-white opacity-75 font-trade-gothic font-extrabold',
          size === 'large' ? 'text-32' : 'text-12'
        )}
      >
        {initials}
      </div>
    </div>
  );
};

Avatar.defaultProps = {
  size: 'large',
};

export default Avatar;
