import React, { FC } from 'react';
import { Layout, LearnHeader, withPrivateRoute } from '@components/molecules';
import { Theme } from '@components/molecules/layout';
import { graphql, PageProps } from 'gatsby';
import { Link } from '@components/atoms';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import ArticleListItem from './articleListItem';

interface PageContextProps {
  lang: string;
  nextPagePath: string | null;
  prevPagePath: string | null;
}

const ArticleList: FC<PageProps<Queries.GetArticleListDataQuery, PageContextProps>> = ({
  data,
  pageContext,
}) => (
  <Layout metaData={{ title: 'All Articles' }} theme={Theme.White}>
    <LearnHeader
      lang={pageContext.lang}
      title={data.prismicArticleHub?.data?.page_heading?.text}
      image={data.prismicArticleHub?.data?.page_header_image}
    />

    <div className="max-w-3xl mx-auto px-4 py-8 space-y-8">
      {data.allPrismicLearnPage.nodes.map(
        (article) =>
          article.url && (
            <ArticleListItem
              key={article.uid}
              title={article.data?.title?.text}
              blurb={article.data?.meta_description}
              url={article.url}
              image={article.data?.display_image}
              lastPublicationDate={article.first_publication_date}
            />
          )
      )}
    </div>

    <div className="max-w-3xl mx-auto px-4 flex items-center justify-center pb-8 space-x-6">
      <div className="hidden md:block bg-black opacity-10 h-px w-full" />

      {pageContext.prevPagePath && (
        <Link
          replace
          to={pageContext.prevPagePath}
          className="bg-grey text-white py-4 px-8 text-12 font-avenir-heavy tracking-wider leading-tight uppercase hover:bg-opacity-75 focus:bg-opacity-75"
        >
          Previous
        </Link>
      )}

      {pageContext.nextPagePath && (
        <Link
          replace
          to={pageContext.nextPagePath}
          className="bg-grey text-white py-4 px-8 text-12 font-avenir-heavy tracking-wider leading-tight uppercase hover:bg-opacity-75 focus:bg-opacity-75"
        >
          Next
        </Link>
      )}

      <div className="hidden md:block bg-black opacity-10 h-px w-full" />
    </div>
  </Layout>
);

export default withPrismicPreview(withPrivateRoute(ArticleList));

export const query = graphql`
  query GetArticleListData($skip: Int!, $limit: Int!, $lang: String!) {
    prismicArticleHub(lang: { eq: $lang }) {
      _previewable
      data {
        page_heading {
          text
        }
        page_header_image {
          gatsbyImageData
          alt
        }
      }
    }

    allPrismicLearnPage(
      sort: { first_publication_date: DESC }
      skip: $skip
      limit: $limit
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        uid
        url
        _previewable
        data {
          title {
            text
          }
          meta_description
          display_image {
            gatsbyImageData
            alt
          }
        }
        first_publication_date
      }
    }
  }
`;
