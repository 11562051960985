import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h2`
  margin-bottom: 8px;
  font-family: 'Avenir heavy';
  font-size: 24px;
  line-height: 40px;
  font-weight: 800;
`;

export const PaymentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;

  & .edit-link {
    font-family: 'Avenir roman';
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: ${(props) => props.theme.colors.greyLetter};
    text-decoration: underline;
  }
`;

export const CcNumberWrapper = styled.div`
  display: flex;
  font-family: 'Avenir roman';
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  align-items: center;

  & svg {
    margin-right: 8px;
  }
`;
