import styled from 'styled-components';
import COLORS from '../../../themes/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 332px;
`;

export const Title = styled.h1`
  margin-bottom: 12px;
  font-family: 'Avenir heavy';
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;
`;

export const Description = styled.div`
  text-align: center;
  font-family: 'Avenir roman';
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 32px;
  color: ${COLORS.greyLetter}

  > p:not(:last-child) {
    margin-bottom: 16px;
  }

  a {
    text-decoration: underline;
  }
`;

export const SecondaryButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const TryAgainButton = styled.button`
  margin-left: 4px;
  background-color: unset;
  border: unset;
  color: ${COLORS.darkGrey};
  text-decoration-line: underline;

  &:not([disabled]):hover {
    background-color: unset;
    text-decoration-line: none;
  }
`;
