/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { breakpoint } from '../../../themes/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.white};
  text-align: center;

  & .submit-button {
    width: 320px;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
`;

export const BackBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  left: 16px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.darkGreyOpacity01};
  transition: border-color 0.15s;
  transition-timing-function: ease-out;

  &:hover {
    border-color: ${(props) => props.theme.colors.darkGrey};
  }

  ${breakpoint('md')`
    left: 22px;
  `}
`;

export const Title = styled.h1`
  font-size: 28px;
  line-height: 36px;
  font-family: 'Avenir heavy';
  margin-bottom: 8px;
`;

export const SubTitle = styled.p`
  font-size: 12px;
  font-family: 'Avenir medium';
  color: ${(props) => props.theme.colors.darkGrey};
`;

export const SubTitleTiering = styled(SubTitle)`
  font-size: 16px;
  font-weight: 900;
`;

export const Description = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.colors.darkGrey};
  margin-bottom: 10px;
`;

export const AffiliateLegalDescription = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.colors.darkGrey};
`;

export const ChangePlan = styled.div`
  font-size: 14px;
  font-family: 'Avenir medium';
  text-transform: uppercase;
  cursor: pointer;
  color: black;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
  ${breakpoint('md')`
    padding: 0;
    margin-top:20px;
    & .MuiButton-root {
      margin: 10px 1px;
    }
  `}
`;

export const PlansScrollContainer = styled.div`
  margin-top: 10px;
`;

export const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  align-items: center;
`;

export const SwitchWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TieringPricingCardWrapper = styled.div`
  margin-bottom: ${(props) => (props.smallDescription ? '40px' : '0px')};
  width: 100%;
  display: flex;
  justify-content: center;
`;
