import styled from 'styled-components';
import { themes } from '@lesmills-international/components';
import { breakpoint } from '../../../themes/breakpoints';

export const Divider = styled.hr`
  margin: 32px auto;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => themes[theme.themeColor || 'white'].colors.borderSecondary};
  border: none;
`;

export const PaymentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 28px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  & button {
    margin-bottom: 100px;
  }
`;

export const WrapperTiering = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & button {
    margin-bottom: 100px;
  }
`;

export const TermsCondition = styled.div`
  max-width: 779px;
  text-align: center;
  font-family: 'Avenir roman';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 24px;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentPrimary};
`;

export const SelectedPlanName = styled.span`
  font-family: 'Avenir heavy';
`;

export const CancellationButton = styled.button`
  font-family: 'Avenir heavy';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  pointer: cursor;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentNegative};
`;

export const PricingCardButtonContainer = styled.div`
  display: flex;
  min-width: 375px;
  justify-content: flex-end;

  ${breakpoint('lg')`
  display: flex;
  min-width: 0;
  align-self: flex-end;
`}
`;
