import styled from 'styled-components';

const Wrapper = styled.div`
  & .MuiFilledInput-root {
    height: 48px;
    margin: 5px 0;
    background-color: ${(props) =>
      props.backgroundColor ?? props.theme.colors.whiteSmoke} !important;
    border-radius: 0;
  }

  & .MuiFilledInput-root:after {
    border-bottom: none;
  }

  & .MuiFilledInput-root.Mui-disabled:before {
    border-bottom: none;
  }

  & .MuiFilledInput-root.Mui-error:after {
    border-bottom: none;
  }

  & .MuiFilledInput-root.Mui-error {
    border-bottom: 2px solid ${(props) => props.theme.colors.errorRed};
  }

  & .MuiInputLabel-root {
    font-size: 14px;
    line-height: 18px;
    margin-left: 8px;
    margin-top: 4px;
    color: ${(props) => props.theme.colors.doveGrey};
    font-family: 'Avenir roman';
    letter-spacing: 0.02em;
  }

  & .MuiInputLabel-root.Mui-error {
    color: ${(props) => props.theme.colors.errorRed};
  }

  & .MuiInputLabel-asterisk.Mui-error {
    color: ${(props) => props.theme.colors.errorRed};
  }

  & .MuiInputLabel-root.MuiInputLabel-shrink {
    color: ${(props) => props.theme.colors.doveGrey};
    line-height: 18px;
    margin-top: 6px;
  }

  & .MuiInputBase-input {
    box-sizing: border-box;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    font-family: 'Avenir roman';
    letter-spacing: 0.02em;
  }

  & .MuiFilledInput-root.Mui-focused .MuiInputBase-input {
    box-sizing: border-box;
    height: 48px;
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.lightGreyD9};
  }

  & .MuiFilledInput-underline:before {
    border-bottom: none;
  }

  & .MuiFilledInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }

  & .MuiFormHelperText-root {
    color: ${(props) => props.theme.colors.darkGrey};
    margin: 0 0 10px 0;
    font-size: 10px;
    line-height: 14px;
    font-family: 'Avenir roman';
    letter-spacing: 0.02em;
  }

  & .MuiFormHelperText-root.Mui-error {
    color: ${(props) => props.theme.colors.errorRed};
    font-size: 12px;
    line-height: 18px;
  }
`;

export default Wrapper;
