/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useMemo, useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';

// eslint-disable-next-line import/no-unresolved
import { parseJwt } from '@src/utils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled from 'styled-components';

import Box from '@mui/material/Box';

import { Button, Password, TextField } from '@lesmills-international/components';

import { Auth } from '@aws-amplify/auth';
import {
  deleteLocalStorage,
  getFormattedParamInfoFromURL,
  getLocalStorage,
  getAutomationRecaptcha,
  setLocalStorage,
  getLangFromURL,
} from '../../../../src/utils/utilities';
import { ReactComponent as CorrectIcon } from '../assets/icons/correct.svg';
import { ReactComponent as WrongIcon } from '../assets/icons/wrong.svg';
import Alert from '../components/common/alert';
import Layout from '../components/common/layout';
import Title from '../components/common/title';
import RecaptchaV2 from '../components/common/recaptchaV2';
import useFormStartPushTracking from '../hooks/useFormStartPushTracking';
import useDisplayRecaptchaBadge from '../hooks/useDisplayRecaptchaBadge';
import { LoginLink, Subtitle, Wrapper } from '../components/signup/createAccount/style';
import { ACTIVATION_CODE_FORMATTED } from '../constants/localStorageKeys';
import { checkHaveErrors, formValidator } from '../utils/validator';

import { TIERS } from '../../../../src/utils/constants';

import { authContext } from '../../../gatsby-theme-engagement/src/context';
import { Link } from '../../../gatsby-theme-engagement/src/components/atoms';
import { sessionReceiverURL } from '../../../gatsby-theme-engagement/src/services';
import { addErrorInDatadogRum } from '../utils/utilities';
import generateRedirectionUrl from '../../../gatsby-theme-engagement/src/utils/generateLoginRedirectionUrl';
import { FormErrorName, FormName, StepLabel } from '../types/DataTracking';
import { formErrorTracking, formSubmitTracking } from '../utils/dataTracking';

const Form = styled(Box)(() => ({
  '& .MuiTextField-root': { marginBottom: '15px', width: '320px' },
  maxWidth: '320px',
  textAlign: 'center',

  '& .MuiInputBase-input:focus': { boxShadow: 'none' },
}));

const CurrentUser = styled.h1`
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.darkGrey};
  font-family: 'Trade Gothic';
  margin-bottom: 30px;
`;

const ForgotPassword = styled.a`
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  padding-bottom: 2px;
  font-size: 12px;
`;

const LoginPage = ({ location, data }) => {
  const { prismicSignUpLayout, prismicLoginPage } = data;

  const {
    email,
    forgot_password,
    invalid_error,
    welcome_back,
    log_in,
    verify_you,
    new_to_les_mills_plus,
    password,
    password_minimum_error,
    password_space_error,
    required_error,
    sign_up_now,
  } = prismicLoginPage?.data || {};

  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const partnerSignupEmail = JSON.parse(getLocalStorage(ACTIVATION_CODE_FORMATTED) || null)?.email;
  const partnerActivationCode = JSON.parse(
    getLocalStorage(ACTIVATION_CODE_FORMATTED) || null
  )?.activationCode;

  const defaultTrackingData = { formName: FormName.LOGIN_FORM, stepLabel: StepLabel.LOGIN };

  useDisplayRecaptchaBadge();

  const recaptchaAutomation = getAutomationRecaptcha();

  const { handleSignIn, isAuthenticated } = useContext(authContext);
  const [tier, setTier] = useState(null);
  // recaptcha v3 initialised from acquisition gatsby-browsjer.js
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [emailHelperText, setEmailHelperText] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const [currentUserName, setCurrentUserName] = useState('');
  const [isFromApp, setIsFromApp] = useState(false);

  const [state, setState] = useState({
    email: '',
    password: '',
    errorMessage: '',
    isProcessingSignIn: false,
  });
  const [formErrors, setFormErrors] = useState({
    email: {
      errorMsg: '',
      valid: false,
    },
    password: {
      errorMsg: '',
      valid: false,
    },
  });

  useEffect(() => {
    setCurrentUserName(urlParams?.get('first_name'));
    setIsFromApp(urlParams?.get('redirect_to')?.includes('?from=app'));
    setState({
      email: urlParams?.get('email') || partnerSignupEmail || '',
      password: '',
      errorMessage: '',
      isProcessingSignIn: false,
    });

    const tiering = urlParams?.get('tiering');

    if (tiering !== null) {
      setLocalStorage('isTieringEnabled', tiering);
    } else {
      deleteLocalStorage('isTieringEnabled');
    }
  }, [urlParams, partnerSignupEmail]);

  useEffect(() => {
    if (tier) {
      const encodedRedirectTo = urlParams?.get('redirect_to');
      const redirectionUrl = generateRedirectionUrl({
        redirectTo: encodedRedirectTo,
        partnerActivationCode,
        tier,
      });

      window.location.href = redirectionUrl;
    }
  }, [tier, urlParams, partnerActivationCode]);

  const { handleFormStarted } = useFormStartPushTracking(defaultTrackingData);

  const handleFormErrorTracking = (errorName) => {
    formErrorTracking({
      ...defaultTrackingData,
      formErrorName: errorName,
    });
  };

  const recaptchaRef = useRef();

  const discountInfoFormatted = getFormattedParamInfoFromURL(location);
  // Store in localStorage
  discountInfoFormatted
    ? setLocalStorage('discountInfo', JSON.stringify(discountInfoFormatted))
    : deleteLocalStorage('discountInfo');

  const lang = getLangFromURL({ withLeadingSlash: true });
  const signUpRef = `${process.env.GATSBY_GETTING_STARTED_URL}signup/create-account${lang}`;
  const forgotPassRef = `${process.env.GATSBY_MAIN_LES_MILLS_URL}/forgot-password`;

  const handleValidate = () => {
    const emailError = validateSingleField('email', state.email);
    const passwordError = validateSingleField('password', state.password);

    const errors = {
      email: emailError,
      password: passwordError,
    };

    setFormErrors(errors);
    return errors;
  };

  const handleSingleValidate = (field, event) => {
    const error = validateSingleField(field, event.target.value);
    if (!error.errorMsg) {
      setSubmitDisabled(false);
    }
    setFormErrors({ ...formErrors, [field]: error });
  };

  const handlePasswordValidate = (event) => {
    const error = validateSingleField('password', event.target.value);
    if (error.errorMsg) {
      setPasswordHelperText(<span>{formErrors.password.errorMsg}</span>);
    }
  };

  const handlePasswordHelperText = (value) => {
    const passwordError = validateSingleField('password', value, true);
    const helperTextIcon = passwordError.valid ? (
      <CorrectIcon style={{ display: 'inline-block', marginLeft: '5px' }} />
    ) : (
      <WrongIcon style={{ display: 'inline-block', marginLeft: '5px' }} />
    );
    setPasswordHelperText(
      <span>
        {password_minimum_error}
        {helperTextIcon}
      </span>
    );
  };

  const handlePasswordChange = (event) => {
    handlePasswordHelperText(event.target.value);
    setFormErrors({ ...formErrors, password: { errorMsg: '', valid: formErrors.password.valid } });
  };

  useEffect(() => {
    if (formErrors.password.errorMsg) {
      setPasswordHelperText(formErrors.password.errorMsg);
    }
    if (formErrors.email.errorMsg) {
      setEmailHelperText(formErrors.email.errorMsg);
    }

    if (!formErrors.email.errorMsg) {
      setEmailHelperText('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  // TODO Use prismic data
  const validateSingleField = (name, value, isPasswordOnChange) =>
    formValidator(
      ['REQUIRED', name.toUpperCase()],
      value,
      {
        requiredError: required_error.replace('{name}', name),
        invalidError: invalid_error.replace('{name}', name),
        passWordSpaceError: password_space_error,
      },
      isPasswordOnChange
    );

  const onSignInSuccess = () => {
    setLocalStorage('isManualLogin', 'true');

    // rewrite this when we introduce social login
    setLocalStorage('loginType', 'email');
    formSubmitTracking(defaultTrackingData);
  };

  const showCurrentUser = Boolean(currentUserName);

  // TODO move this title and subtitle to Prismic
  const title = isFromApp ? welcome_back : log_in;
  const subtitle = isFromApp ? (
    <Subtitle>{verify_you}</Subtitle>
  ) : (
    <LoginLink id="have-an-account">
      {new_to_les_mills_plus}{' '}
      <Link to={signUpRef} className="underline">
        {sign_up_now}
      </Link>
    </LoginLink>
  );

  const onSignfailure = (errorMessage) => {
    handleFormErrorTracking(FormErrorName.SERVER_ERROR);
    if (errorMessage === 'PreAuthentication failed with error Failed reCaptcha V3 verification.') {
      // trigger recaptcha v2
      recaptchaRef.current.executeAsync().then((token) => {
        const validationData = {
          Name: 'recaptchaToken',
          Value: token,
        };
        handleSignIn(state.email, state.password, validationData, onSignInSuccess, onSignfailure);
      });
    } else {
      setState({ ...state, errorMessage, isProcessingSignIn: false });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = handleValidate();
    if (checkHaveErrors(errors)) {
      handleFormErrorTracking(FormErrorName.INCOMPELTE_FIELDS);
      return;
    }
    setState({ ...state, isProcessingSignIn: true });
    // store user email in local, for downstream components use.
    setLocalStorage('email', state.email);

    if (recaptchaAutomation) {
      // Bypass reCAPTCHA for automated testing
      handleSignIn(
        state.email,
        state.password,
        recaptchaAutomation,
        onSignInSuccess,
        onSignfailure
      );
    } else {
      // prettier-ignore
      executeRecaptcha('SignIn')
        .then(
          (token) => {
            const validationData = { Name: 'recaptchaTokenV3', Value: token };
            handleSignIn(state.email, state.password, validationData, onSignInSuccess, onSignfailure)
          }
        )
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const fetchTier = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        const userInfoJwt = parseJwt(user.signInUserSession.idToken.jwtToken);
        const isBaseTier = userInfoJwt?.tierId?.includes(TIERS.BASE);
        setTier(isBaseTier ? TIERS.BASE : TIERS.PREMIUM);
      } catch (error) {
        addErrorInDatadogRum(error);
        // if fails set it to Premium by default
        setTier(TIERS.PREMIUM);
      }
    };

    fetchTier();
  }, [isAuthenticated]);

  return (
    <Layout data={prismicSignUpLayout?.data} lang="en-NZ" pathName="/login" omitClose>
      <Wrapper>
        {/* if URL link contains userEmail, the email will be prepopulated */}
        {showCurrentUser ? <CurrentUser>Welcome back, {currentUserName}</CurrentUser> : null}
        <Title
          id="create-account-title"
          variant={!showCurrentUser ? 'h1' : 'h2'}
          style={{ marginTop: '24px' }}
        >
          {title}
        </Title>
        {subtitle}
        {state.errorMessage && <Alert content={state.errorMessage} />}

        <RecaptchaV2 ref={recaptchaRef} />
        <Form id="login-form" component="form" noValidate>
          {TextField && (
            <TextField
              required
              label={email}
              variant="filled"
              name="email"
              type="email"
              autoComplete="email"
              id="email"
              onClick={() => handleFormStarted()}
              value={state.email}
              error={!!formErrors.email.errorMsg}
              helperText={emailHelperText}
              onChange={(e) => setState({ ...state, email: e.target.value })}
              onBlur={(event) => {
                handleSingleValidate('email', event);
              }}
            />
          )}

          {Password && (
            <Password
              id="password"
              label={password}
              name="password"
              value={state.password}
              autoComplete="password"
              error={formErrors.password.errorMsg.length}
              style={{ '&:focus': { boxShadow: 'none' } }}
              onBlur={(e) => {
                handlePasswordValidate(e);
                handleSingleValidate('password', e);
              }}
              className="focus:ring-0 focus:ring-offset-0 shadow-none"
              onChange={(e) => {
                handlePasswordChange(e);
                setState({ ...state, password: e.target.value });
              }}
              helperText={passwordHelperText}
            />
          )}

          <ForgotPassword href={forgotPassRef}>{forgot_password}</ForgotPassword>

          {Button && (
            <Button
              type="submit"
              id="submit-button"
              disabled={submitDisabled}
              loading={state.isProcessingSignIn}
              variant="contained"
              ctaButton
              style={{ width: '100%', marginTop: '20px' }}
              onClick={handleSubmit}
              label={log_in}
            />
          )}
        </Form>

        {sessionReceiverURL && (
          <iframe
            title="session_receiver"
            id="session_receiver"
            src={sessionReceiverURL}
            width="0"
            height="0"
          />
        )}
      </Wrapper>
    </Layout>
  );
};

export default LoginPage;

export const query = graphql`
  query ($lang: String) {
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicSignUpLayoutDataBody1Countries {
            items {
              country_code {
                text
              }
              country_name {
                text
              }
            }
          }
        }
        body2 {
          ... on PrismicSignUpLayoutDataBody2States {
            primary {
              country_name {
                text
              }
              country_code {
                text
              }
            }
            items {
              state_name {
                text
              }
              state_code {
                text
              }
              state_short_name {
                text
              }
            }
          }
        }
      }
    }
    prismicLoginPage(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        email
        forgot_password
        invalid_error
        welcome_back
        log_in
        verify_you
        new_to_les_mills_plus
        password
        password_minimum_error
        password_space_error
        required_error
        sign_up_now
      }
    }
  }
`;
