import styled from 'styled-components';

import { breakpoint } from '../../../themes/breakpoints';

export const ReceiveEmailLabel = styled.p`
  width: 320px;
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.darkGrey};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 100px;
  background-color: ${(props) => props.theme.colors.white};

  & .MuiFormControl-root.MuiTextField-root {
    width: 320px;
    margin: 0 0 12px 0;
  }

  & .submit-button {
    margin: 10px 1px;
  }

  & .submit-button:hover {
    background-color: ${(props) => props.theme.colors.lightBlack} !important;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }

  & .edit-email-btn {
    letter-spacing: 0.02em;
  }

  & .existing-email-login-link {
    text-decoration: underline;
  }

  & .email-existed-helper {
    color: ${(props) => props.theme.colors.errorRed};
    font-size: 12px;
    line-height: 18px;
    text-align: left;
  }

  & .mobile-container {
    margin-bottom: 350px;
  }

  & .form-field-helper {
    color: ${(props) => props.theme.colors.errorRed};
    font-size: 12px;
    line-height: 18px;
    text-align: left;
  }

  ${breakpoint('md')`
    margin-bottom: 40px;
  `}

  ${breakpoint('lg')`
    margin-bottom: 20px;
  `}

  & .alert--error {
    a {
      text-decoration: underline;
    }
  }
`;

export const Logo = styled.div`
  margin-bottom: 12px;
  text-align: center;
  display: none;

  ${breakpoint('md')`
    display: block;
  `}

  ${breakpoint('lg')`
    display: none;
  `}
`;

export const BackBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  left: 16px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.darkGreyOpacity01};
  transition: border-color 0.15s;
  transition-timing-function: ease-out;

  &:hover {
    border-color: ${(props) => props.theme.colors.darkGrey};
  }

  ${breakpoint('md')`
    top: 16px;
    left: 22px;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;

  & .MuiButton-root {
    margin: 0 1px;
  }

  ${breakpoint('md')`
    width: auto;
    position: relative;
    bottom: 0;
    box-shadow: none;
    padding: 0;

    & .MuiButton-root {
      margin: 10px 1px;
    }
  `}
`;

export const EmailPrivacyText = styled.div`
  width: 320px;
  margin: 0 auto;
`;

export const EditBtn = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.darkGrey};
  margin-right: 6px;
  cursor: pointer;
  text-decoration: underline;
`;

export const Privacy = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  a {
    text-decoration: underline;
  }
`;

export const LoginLink = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 24px;
  font-family: 'Avenir medium';
  color: ${(props) => props.theme.colors.darkGrey};
  & a {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.darkGrey};
  }

  ${breakpoint('md')`
    font-size: 12px;
  `}
`;
