import { themes } from '@lesmills-international/components';
import styled, { css } from 'styled-components';
import { breakpoint } from '../../../themes/breakpoints';

export type Size = 'wide' | 'medium' | 'narrow';

export const Container = styled.div<{ hideHeader?: boolean; backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 0px;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ||
    (theme.themeColor === 'dark'
      ? themes[theme.themeColor].colors.surfacePrimary
      : theme.colors.white.op100)};

  ${({ hideHeader }) =>
    hideHeader &&
    css`
      padding-top: 16px;

      ${breakpoint('lg')`
  padding-top: 32px;
`}
    `};
`;

export const LogoContainer = styled.div`
  margin-bottom: 20px;
`;

export const Wrapper = styled.div<{ size?: Size; alignCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-start')};

  /* narrow: 320px, medium: 720px, wide: 1056px */
  max-width: ${({ size }) =>
    size === 'medium' ? '760px' : size === 'narrow' ? '320px' : '1056px'};
  margin: 0 auto;
  padding: ${({ size }) => (size === 'narrow' ? '32px 0' : '32px 16px')};

  & .back-link {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    font-family: 'Avenir roman';
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-decoration-line: underline;

    & svg {
      margin-right: 12px;
    }
  }
`;

export const Title = styled.h1<{ marginBottom?: number }>`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 16}px;
  font-family: 'Avenir heavy';
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;
  color: ${({ theme }) => themes[theme?.themeColor ?? 'white'].colors.contentPrimary};
`;

export const SubTitle = styled.h2`
  margin-bottom: 16px;
  font-family: 'Avenir';
  font-size: 16px;
  line-height: 24px;
`;
