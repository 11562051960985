import React from 'react';
import PropTypes from 'prop-types';

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select as MUISelect,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Wrapper from './style';

const useStyles = makeStyles({
  select: {
    background: `${(props) => props.theme.colors.white} !important`,
    border: `1px solid ${(props) => props.theme.colors.borderGrey} !important`,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16) !important',
    borderRadius: '0 !important',
    '& li': {
      fontSize: 14,
      lineHeight: '33px',
      letterSpacing: '0.02em',
      fontFamily: 'Avenir roman',
    },
  },
});

const Select = ({ label, value, handleChange, items, error, helperText }) => (
  <Wrapper>
    <FormControl required variant="filled" fullWidth error={error}>
      <InputLabel id="state-select-label">{label}</InputLabel>
      <MUISelect
        labelId="state-select-label"
        id="state-select"
        MenuProps={{ classes: { paper: useStyles().select } }}
        value={value}
        label={`${label} *`}
        onChange={handleChange}
      >
        {items.length &&
          items.map((item) => (
            <MenuItem key={item.state_code?.text} value={item.state_name?.text}>
              {item.state_name?.text}
            </MenuItem>
          ))}
      </MUISelect>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  </Wrapper>
);

Select.defaultProps = {
  label: '',
  value: '',
  items: [],
  handleChange: () => {},
  error: false,
  helperText: '',
};

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  handleChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default Select;
