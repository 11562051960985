/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const PlanDetailCopy = {
  join: {
    header: 'JOIN THE PLAN?',
    desc: 'Are you ready to commit to an amazing 6 weeks of change? Hit the button and enjoy the ride!',
    buttonPrimary: 'JOIN NOW',
    buttonSecondary: 'MAYBE LATER',
  },
  switch: {
    header: 'SWITCH YOUR PLAN?',
    desc: 'You can only be on one plan at a time. Only hit the button if you want to switch from your old plan.',
    buttonPrimary: 'SWITCH NOW',
    buttonSecondary: 'NEVER MIND',
  },
};

interface ConfirmationProps {
  type: 'join' | 'switch';
  onConfirm: () => void;
  onCancel: () => void;
}

const PlanConfirmation = ({ type, onConfirm, onCancel }: ConfirmationProps) => (
  <div style={{ width: '420px', height: '435px' }} className="bg-white flex flex-col">
    <StaticImage src="../../../assets/new-placeholder.jpg" alt="" />
    <div className="flex flex-col items-center gap-6 my-5">
      <div className="font-trade-gothic font-bold text-40 leading-none tracking-tighter uppercase">
        {PlanDetailCopy[type].header}
      </div>
      <div className="text-center font-avenir text-14 leading-5 mx-5">
        {PlanDetailCopy[type].desc}
      </div>
      <div
        className="w-80 h-12 bg-black rounded flex justify-center items-center text-white text-12 font-avenir-heavy tracking-wider uppercase cursor-pointer"
        onClick={onConfirm}
      >
        {PlanDetailCopy[type].buttonPrimary}
      </div>
      <div
        className="text-12 font-avenir-heavy tracking-wider uppercase cursor-pointer"
        onClick={onCancel}
      >
        {PlanDetailCopy[type].buttonSecondary}
      </div>
    </div>
  </div>
);

export default PlanConfirmation;
