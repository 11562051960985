import React, { FC } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import classNames from 'classnames';

interface Props {
  title?: string;
  blurb?: string;
  url?: string;
  image?: {
    gatsbyImageData?: IGatsbyImageData;
    alt?: string;
  };
  reversed?: boolean;
  small?: boolean;
}

const ArticleSummary: FC<Props> = ({ title, blurb, url, image, reversed, small }) => (
  <div className="grid grid-cols-5">
    <div
      className={classNames(
        'col-span-5 md:col-span-2',
        reversed ? 'order-2 md:order-1' : 'md:order-2'
      )}
    >
      {image?.gatsbyImageData && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ?? ''}
          className="object-cover w-full h-64 md:h-full z-10"
        />
      )}
    </div>
    <div
      className={classNames(
        'col-span-5 md:col-span-3 bg-white space-y-4',
        reversed ? 'order-1 md:order-2' : 'md:order-1',
        small ? 'py-5 px-6' : 'px-6 md:px-10 py-6 md:py-8'
      )}
    >
      {title && (
        <h2 className="text-grey font-trade-gothic leading-tight text-24 uppercase">{title}</h2>
      )}

      {blurb && <p className="font-avenir text-14 text-grey opacity-75 line-clamp-5">{blurb}</p>}

      {url && (
        <div className="flex">
          <Link
            to={url}
            className="flex space-x-2 font-avenir text-13 tracking-wide text-grey opacity-75 hover:underline"
          >
            Read more&hellip;
          </Link>
        </div>
      )}
    </div>
  </div>
);

export default ArticleSummary;
