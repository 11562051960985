import React, { FC, ReactNode, createContext, useMemo } from 'react';
import { parseJwt, getLocalStorage, setLocalStorage } from '@src/utils';
import { useLDClient } from 'gatsby-plugin-launchdarkly';

import { UserEntitlements, useGetUserQuery } from '@backend';
import type {
  Subscription,
  ChargifyPaymentProfile,
  PaymentMethod,
  WorkoutFavorites,
} from '@backend';
import { TIERS, TIER_DEFAULT } from '@constants';
import { isEqual } from 'lodash';
import { ApolloError } from '@apollo/client';

const TIERS_AV = Object.entries(TIERS).map((i) => i[1]);

interface ProviderProps {
  children?: ReactNode;
}

enum Tier {
  PREMIUM = 'premium',
  BASE = 'base',
}

export interface UserContext {
  isLoading: boolean;
  hasActiveSubscription: boolean | null;
  vimeoUserId: string | null;
  givenName: string | null;
  familyName: string | null;
  isNewUser: boolean | null;
  isEntitledToFreeTrial: boolean | null;
  userId: string | null;
  chargifyUserId: string | null;
  type: string | null;
  signupChannel: string | null;
  lmodSubscription: Subscription | null;
  userEntitlements: UserEntitlements | null;
  chargifyPaymentProfile: ChargifyPaymentProfile | null;
  paymentMethod: PaymentMethod | null;
  programFavorites: string[] | null;
  addressStreet: string | null;
  addressStreet2: string | null;
  addressCountry: string | null;
  addressPostalCode: string | null;
  addressRegion: string | null;
  addressStateCode: string | null;
  addressLocality: string | null;
  locale: string | null;
  tier: Tier | null;
  email: string | null;
  tuneAffiliateId: string | null;
  subComms: boolean | null;
  subCommsConfirmed: boolean | null;
  refetchUser?: () => void;
  error?: null | ApolloError;
  workoutFavorites: WorkoutFavorites | null;
}

const userContext = createContext<UserContext>({
  isLoading: true,
  hasActiveSubscription: null,
  vimeoUserId: null,
  givenName: null,
  familyName: null,
  isNewUser: null,
  isEntitledToFreeTrial: null,
  userId: null,
  chargifyUserId: null,
  type: null,
  signupChannel: null,
  userEntitlements: null,
  lmodSubscription: null,
  chargifyPaymentProfile: null,
  paymentMethod: null,
  programFavorites: null,
  addressStreet: null,
  addressStreet2: null,
  addressCountry: null,
  addressPostalCode: null,
  addressRegion: null,
  addressStateCode: null,
  addressLocality: null,
  locale: null,
  tier: null,
  email: null,
  tuneAffiliateId: null,
  subComms: null,
  subCommsConfirmed: null,
  workoutFavorites: null,
});

const getTierName = (tierId: String) => {
  // safe guard against anything being in the tier for now
  const name = tierId?.split('#')[1];
  return TIERS_AV.includes(name) ? name : TIER_DEFAULT;
};

const UserProvider: FC<ProviderProps> = ({ children }) => {
  const { loading, data, refetch, error } = useGetUserQuery();
  const userInfoJwt = parseJwt(localStorage.getItem('jwtToken'));
  const { getUser: user } = data || {};
  const tier = getTierName(userInfoJwt?.tierId ?? '');
  const hasActiveSubscription = user?.lmodEntitlement ?? null;

  const ldClient = useLDClient();

  const refetchUser = () => refetch();

  if (user) {
    // Calling `identify` will cause the flags to be re-evaluated for the new
    // user that's logged in. Changes in flag values will stream in and could.
    // If this is a problem, set the flags inside the promise returned
    // from `ldClient.identify`.
    // can access flags anywhere in the app by calling `useFlags()`
    // set updated User Info to local storage

    const existingUser = JSON.parse(getLocalStorage('user-info') || 'null');

    if (!isEqual(user, existingUser)) {
      setLocalStorage('user-info', JSON.stringify(user));
    }

    ldClient?.identify({
      key: user.userId,
      custom: { tierId: tier as string },
    });
  }

  const providerValue = useMemo(
    () => ({
      isLoading: loading,
      hasActiveSubscription,
      tier,
      email: user?.email,
      error,
      refetchUser,
      ...user,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, hasActiveSubscription, user, tier]
  );

  return <userContext.Provider value={providerValue}>{children}</userContext.Provider>;
};

export { userContext, UserProvider };
