/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { isInternalUrl, isVimeoUrl } from '@services';
import { userContext } from '@context';
import { isInExplorePage } from '../../../utils/utilities';

export const Link = forwardRef((props, ref) => {
  const [isActive, setIsActive] = useState(false);
  const { userEntitlements } = useContext(userContext);
  const vimeoTicket = userEntitlements?.LMOD?.vimeoUserTicket;

  const { to, children, activeClassName, className, ...rest } = props;

  useEffect(() => {
    // set navLink to active when it's in explore page
    const currentPath = window.location.href;
    const isInTheSameLocation = to === currentPath;
    const isInExplore = isInExplorePage(to) && isInExplorePage(currentPath);
    setIsActive(isInTheSameLocation || isInExplore);
  }, [to]);

  // Linking to a url within the gatsby site
  if (isInternalUrl(to)) {
    return (
      <GatsbyLink
        activeClassName={activeClassName}
        className={className}
        to={to}
        {...rest}
        ref={ref}
      >
        {children}
      </GatsbyLink>
    );
  }

  // Linking to a vimeo url
  if (isVimeoUrl(to)) {
    return (
      <a href={`${to}?ticket=${vimeoTicket}`} className={className} {...rest} ref={ref}>
        {children}
      </a>
    );
  }

  // All other links
  return (
    <a
      href={to}
      {...rest}
      className={isActive ? [className, activeClassName].join(' ') : className}
      ref={ref}
    >
      {children}
    </a>
  );
});

export default Link;
