/* eslint-disable no-case-declarations */
import React, { FC, useState, useEffect, useRef, useContext, useMemo } from 'react';
import { Tabs, Tab, AlbumCard } from '@lesmills-international/components';
import { Carousel, SlicePlaceholder } from '@components/atoms';
import { useWindowSize } from '@hooks';
import { getCollectionPlaylistWorkoutSlideAmount, formatDuration, getSlideSpace } from '@services';
import { userContext } from '@context';
import { addErrorInDatadogRum } from '../../../utils/utilities';
import GET_COLLECTION from '../../../engagementGql/collection';
import { createEngagementClient } from '../../../../../../src/services/client';

import {
  SpotlightRibbonWrapper,
  TabsWrapper,
  CarouselWrapper,
  SlicePlaceholderWrapper,
} from './style';

interface Props {
  content: Queries.PrismicCollectionPageDataBodySpotlightRibbon;
}

export interface CollectionProps {
  backgroundImg: string;
  description: string;
  id: string;
  itemsCount: number;
  itemsTotal: number;
  name: string;
  shortDescription: string;
  slug: string;
  type: string;
  items: (WorkoutCollectionItemProps | ExtrasCollectionItemProps | CollectionLinkItemProps)[];
}

export interface WorkoutCollectionItemProps {
  duration: number;
  name: string;
  slug: string;
  videoId: string;
  vimeoThumbnail: string;
  description: string;
  workout: {
    workoutName: string;
    categories: string[];
    equipment: string[];
    imageWide: string;
    tiers: string[];
    durationInSeconds: number;
  };
  __typename: string;
}

export interface ExtrasCollectionItemProps {
  downloadUrl: string;
  name: string;
  slug: string | null;
  vimeoThumbnail: string;
  description: string;
  __typename: string;
}

export interface CollectionLinkItemProps {
  collectionId: string;
  description: string;
  name: string;
  slug: string;
  vimeoThumbnail: string;
  __typename: string;
}

export interface WorkOutComponentProps {
  data: (WorkoutCollectionItemProps | ExtrasCollectionItemProps) | null;
}

export enum ItemType {
  WORKOUT = 'WorkoutCollectionItem',
  EXTRA = 'ExtrasCollectionItem',
  COLLECTION = 'CollectionLinkItem',
}

const WorkOutComponent: FC<WorkOutComponentProps> = ({ data }) => {
  const workoutItemData = useMemo(() => {
    switch (data?.__typename) {
      case ItemType.WORKOUT:
        const workoutData = data as WorkoutCollectionItemProps;
        if (!workoutData?.workout?.workoutName) {
          return null;
        }
        return {
          name: workoutData?.workout?.workoutName,
          backgroundImg: workoutData?.workout?.imageWide,
          equipmentIcons: workoutData?.workout?.equipment,
          duration: formatDuration(workoutData?.workout?.durationInSeconds || 0),
          link: `/workout/${encodeURIComponent(workoutData?.workout?.workoutName)}`,
        };
      case ItemType.EXTRA:
        const extraData = data as ExtrasCollectionItemProps;
        return {
          name: extraData?.name,
          duration: '',
          equipmentIcons: [],
          backgroundImg: extraData?.vimeoThumbnail,
          link: extraData?.downloadUrl,
        };
      default:
        return null;
    }
  }, [data]);

  const label = data?.__typename === ItemType.EXTRA ? 'PDF' : 'WORKOUT';

  return workoutItemData ? (
    <AlbumCard
      label={label}
      title={workoutItemData?.name}
      duration={workoutItemData?.duration}
      equipmentIcons={workoutItemData?.equipmentIcons}
      backgroundImg={workoutItemData?.backgroundImg}
      link={workoutItemData?.link}
    />
  ) : null;
};

const WorkoutSpotlightRibbons: FC<Props> = ({ content }) => {
  const firstTabValue = content?.items[0]?.playlist_id;
  const [value, setValue] = useState<string>(firstTabValue || '');
  const [playlistItemsData, setPlaylistItemsData] = useState<CollectionProps[]>([]);
  const [videoCardItems, setVideoCardItems] = useState<JSX.Element[]>([]);
  const [carouselSlidesPerView, setCarouselSlidesPerView] = useState<number>(4);
  const [carouselSlideSpaceBetween, setCarouselSlideSpaceBetween] = useState<number>(8);
  const [loading, setLoading] = useState<boolean>(false);

  const carouselRef = useRef<HTMLDivElement>(null);
  const windowInnerWidth = useWindowSize();
  const userInfo = useContext(userContext);

  const handleOnChangeTab = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleGetPlaylistsData = async () => {
      try {
        setLoading(true);
        if (content?.items?.length) {
          const promises = content?.items?.map(async (item) => {
            if (item.playlist_id) {
              const data = await createEngagementClient.query({
                query: GET_COLLECTION,
                variables: {
                  id: Number(item.playlist_id),
                  page: 1,
                  perPage: 100,
                },
              });
              return data?.data?.collection;
            }
            return null;
          });
          const playlistsData = await Promise.all(promises);
          setLoading(false);
          return playlistsData.filter(Boolean);
        }
      } catch (error) {
        setLoading(false);
        addErrorInDatadogRum(error);
      }
    };

    handleGetPlaylistsData()
      .then((res) => {
        res && setPlaylistItemsData(res);
      })
      .catch((error) => {
        addErrorInDatadogRum(error);
      });
  }, [content]);

  useEffect(() => {
    if (carouselRef && carouselRef.current && playlistItemsData.length) {
      const carouselWrapperWidth = carouselRef.current.clientWidth;
      const slidesPerView = getCollectionPlaylistWorkoutSlideAmount(carouselWrapperWidth);
      const activeTabWorkoutData = playlistItemsData.filter((item) => item?.id === value)[0];
      const workoutVideoItems = activeTabWorkoutData
        ? activeTabWorkoutData?.items?.map((item) => <WorkOutComponent data={item} />)
        : [];
      if (workoutVideoItems?.length < slidesPerView) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (let i = 0; i < slidesPerView - workoutVideoItems.length; i += 1) {
          workoutVideoItems.push(<div />);
        }
      }
      setCarouselSlidesPerView(slidesPerView);
      setCarouselSlideSpaceBetween(getSlideSpace(carouselWrapperWidth));
      setVideoCardItems(workoutVideoItems.filter(Boolean));
    }
  }, [content.items, playlistItemsData, userInfo, value, windowInnerWidth]);

  return (
    <SpotlightRibbonWrapper>
      <TabsWrapper>
        <Tabs value={value} onChange={handleOnChangeTab}>
          {content?.items?.map((tab, index) => (
            <Tab value={tab?.playlist_id} label={tab?.tab_title_text} key={index} />
          ))}
        </Tabs>
      </TabsWrapper>
      {loading ? (
        <SlicePlaceholderWrapper>
          <SlicePlaceholder height="160px" />
        </SlicePlaceholderWrapper>
      ) : (
        <CarouselWrapper ref={carouselRef}>
          <Carousel
            items={videoCardItems}
            slidesPerView={carouselSlidesPerView}
            slidesPerGroup={carouselSlidesPerView}
            spaceBetween={carouselSlideSpaceBetween}
          />
        </CarouselWrapper>
      )}
    </SpotlightRibbonWrapper>
  );
};

export default WorkoutSpotlightRibbons;
