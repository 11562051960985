/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ReactComponent as NextLogo } from '@assets/icons/next.svg';
import { Maybe, WorkoutHistory } from '@backend';
import { ReactComponent as PreviousLogo } from '@assets/icons/previous.svg';
import Slider from 'react-slick';
import WorkoutCard from '../workoutCard';

interface WorkoutsProps {
  workouts: {
    programName: string;
    workoutName: string;
    equipment: string[];
    imageWide: string | null;
    imageWideFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    instructorTeam: string[];
    vimeoWebPageUrl: string;
  }[];
  schedule: {
    day: number;
    workoutName: string;
  }[];
  weeks: number;
  isInProgress: boolean;
  history?: Maybe<Array<Maybe<WorkoutHistory>>>;
}

const PlanWorkouts = ({ workouts, schedule, weeks, isInProgress, history }: WorkoutsProps) => {
  const [indicator, setIndicator] = useState(1);
  const sliderRef = useRef<any>(null);

  const displayedDays = weeks * 7;

  const handleNextSlide = () => {
    if (indicator >= weeks) return;
    sliderRef.current?.slickNext();
    setIndicator(indicator + 1);
  };
  const handlePrevSlide = () => {
    if (indicator <= 1) return;
    sliderRef.current?.slickPrev();
    setIndicator(indicator - 1);
  };

  return (
    <>
      <div className="flex items-center justify-center py-5">
        <div
          className="w-8 h-8 rounded-full bg-grey bg-opacity-10 shadow-sm text-black flex justify-center items-center cursor-pointer"
          onClick={handlePrevSlide}
        >
          <PreviousLogo />
        </div>
        <div className="mx-9 font-avenir text-18 leading-tight text-center font-extrabold">
          Week {indicator}
        </div>
        <div
          className="w-8 h-8 rounded-full bg-grey bg-opacity-10 shadow-sm text-black flex justify-center items-center cursor-pointer"
          onClick={handleNextSlide}
        >
          <NextLogo />
        </div>
      </div>
      <div className="px-5 overflow-x-scroll">
        <div
          className=" border-b border-t border-opacity-10 border-black py-5 mx-auto"
          style={{ width: '1280px' }}
        >
          <div className="font-avenir font-extrabold leading-tight text-18 mb-3">Work Out</div>
          <Slider
            infinite={false}
            slidesToShow={7}
            slidesToScroll={7}
            draggable={false}
            arrows={false}
            swipe={false}
            ref={sliderRef}
          >
            {Array.from({ length: displayedDays }, (_, i) => i + 1).map((today) => {
              const scheduleToday = schedule?.find((item) => item.day === today);
              const currentDay = today % 7;
              const currentWorkout = workouts?.find(
                (wo) => wo?.workoutName === scheduleToday?.workoutName
              );

              const completed =
                (history ?? []).findIndex((h) => {
                  if (h?.workoutName === undefined || currentWorkout?.workoutName === undefined)
                    return false;
                  return h?.workoutName === currentWorkout?.workoutName;
                }) !== -1;

              return (
                <div key={today}>
                  <div className="mb-4 font-avenir text-12 font-extrabold tracking-wider leading-tight text-grey opacity-50">{`DAY ${
                    currentDay || 7
                  }`}</div>
                  <WorkoutCard
                    workout={currentWorkout}
                    isInProgress={isInProgress}
                    completed={completed}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default PlanWorkouts;
