import React, { FC } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, PageProps } from 'gatsby';
import styled from 'styled-components';

import { breakpoint } from '@themes';
import { withPrivateRoute, Layout } from '@components/molecules';
import SliceZone from '@components/sliceZone';
import PageName from '../types/pageName';

const MyWorkoutsWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  padding-bottom: 40px;
  margin-left: 16px;
  margin-right: 16px;

  ${breakpoint('sm')`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${breakpoint('lg')`
    margin-left: 80px;
    margin-right: 80px;
  `}
`;

const Title = styled.h4`
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.lightishGrey};
  font-family: 'Avenir heavy';
  margin: 56px 0 40px;
`;

interface PageContextProps {
  lang: string;
}

const MyWorkoutsTemplate: FC<PageProps<Queries.GetMyWorkoutsDataQuery, PageContextProps>> = ({
  data,
}) => {
  const { prismicMyWorkouts } = data || {};

  return (
    <Layout>
      <MyWorkoutsWrapper>
        <Title>{prismicMyWorkouts?.data?.title}</Title>
        <SliceZone slices={prismicMyWorkouts?.data?.body} pageName={PageName.ALL_PROGRAMS} />
      </MyWorkoutsWrapper>
    </Layout>
  );
};

export default withPrismicPreview(withPrivateRoute(MyWorkoutsTemplate));

export const query = graphql`
  query GetMyWorkoutsData($lang: String!) {
    prismicMyWorkouts(lang: { eq: $lang }) {
      data {
        title
        body {
          ... on PrismicMyWorkoutsDataBodyFavoriteContent {
            id
            slice_type
            items {
              favorite_content_type
              favorite_content_type_label
              empty_status_text
            }
          }
        }
      }
    }
  }
`;
