import styled, { keyframes, css } from 'styled-components';
import { breakpoint } from '@themes';
import COLORS from '@themes/colors';

const animationOverlayFadeIn = keyframes`
  from {
    position: absolute;
    opacity: 0;
    top: -80px
  }
  to {
    position: absolute;
    opacity: 1;
    top: 0px;
  }
`;

export const PageWrapper = styled.div`
  position: fixed;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 200;
`;

export const SearchHeader = styled.div`
  background: ${COLORS.black};
  position: absolute;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    !props.fullScreen &&
    css`
      -webkit-animation: ${animationOverlayFadeIn} 0.2s ease-in-out;
      -moz-animation: ${animationOverlayFadeIn} 0.2s ease-in-out;
      -o-animation: ${animationOverlayFadeIn} 0.2s ease-in-out;
      -ms-animation: ${animationOverlayFadeIn} 0.2s ease-in-out;
      animation: ${animationOverlayFadeIn} 0.2s ease-in-out;
    `}
`;

export const SearchInputWrapper = styled.div`
  background: ${COLORS.black};
  width: 380px;
  ${breakpoint('sm')`
    width: 704px;
  `}
  ${breakpoint('md')`
    width: 800px;
  `}
`;

export const SearchBody = styled.div<{ showOverlay?: boolean }>`
  height: 100%;
  width: 100%;
  background: ${(props) => (props.showOverlay ? COLORS.blackOpacity : COLORS.black)};
`;

export const ResultStates = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-top: 110px;
  padding-left: 16px;
  ${breakpoint('sm')`
    padding-left: 16px;
  `}
  ${breakpoint('md')`
    padding-left: 32px;
  `}
  ${breakpoint('lg')`
    padding-left: 80px;
  `}
  ${breakpoint('xl')`
    padding-left: 80px;
  `}
  & p {
    font-family: Avenir;
    font-style: normal;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: ${COLORS.lightGreyAE};
  }
`;

export const NoResultsWrapper = styled.div`
  width: 100%;
  word-break: break-all;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 96px;
  color: ${COLORS.lightGreyAE};
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  ${breakpoint('sm')`
    padding-left: 16px;
    padding-right: 16px;
  `}
  ${breakpoint('md')`
    padding-left: 16px;
    padding-right: 16px;
  `}
  ${breakpoint('lg')`
    padding-left: 32px;
    padding-right: 32px;
  `}
  ${breakpoint('xl')`
    padding-left: 32px;
    padding-right: 32px;
  `}
`;
