/* eslint-disable import/no-relative-packages */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint max-len: ["error", { "code": 120 }] */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

import Seo from '../components/common/seo';

import withPrivateRoute from '../../../gatsby-theme-engagement/src/components/molecules/withPrivateRoute';
import Footer from '../../../gatsby-theme-engagement/src/components/molecules/footer';
import Header from '../../../gatsby-theme-engagement/src/components/molecules/header';

import { breakpoint } from '../themes/breakpoints';
import COLORS from '../themes/colors';
import Subtitles from '../components/referAFriend/subtitles';
import LinkCopier from '../components/referAFriend/linkCopier';
import Step from '../components/referAFriend/step';

// styles
const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  color: ${COLORS.darkGrey};
  letter-spacing: 0em;
`;

const RafHeader = styled.div`
  width: 100%;
  height: 313px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 26.84%, rgba(0, 0, 0, 0.4) 100%);
  ${breakpoint('md')`
    height:474px;
`}
`;

const Beam = styled.div`
  height: 1px;
`;

const PageTitleWrapper = styled.div`
  width: 100%;
  font-size: 29px;
  text-align: center;
  color: ${COLORS.white};
  margin-top: 150px;
  padding: 0px;
  ${breakpoint('md')`
    margin-top:170px;
`}
`;

const Title = styled.div`
  margin-left: auto;
  font-family: 'Trade Gothic';
  letter-spacing: -0.02em;
  margin-right: auto;
  font-size: 46px;
  line-height: 55px;
  font-weight: 700;
  ${breakpoint('md')`
    font-size: 70px;
    line-height: 84px;
    margin-top:10px;
`}
`;

const ContentWrapper = styled.div`
  position: relative;
  background-color: ${COLORS.white};
  z-index: 2;
  ${breakpoint('md')`
    background-color: #e5e5e5;
    background: none;
`}
`;

const Description = styled.div`
  font-family: Avenir medium;
  margin-top: 30px;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  ${breakpoint('md')`
    margin-left: auto;
    margin-right: auto;
    font-size: 24px;
    line-height: 36px;
    margin-top:30px;
    max-width: 420px;
`}
`;

const Disclaimer = styled.div`
  font-family: Avenir roman;
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 125px;
  ${breakpoint('md')`
    margin-top:20px;
    margin-bottom:20px;
    max-width: 464px;
    width:100%;
    margin-bottom:125px;
`}
`;

const DescriptionWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background-color: ${COLORS.white};
  ${breakpoint('md')`
  width: 100%;
  `}
  ${breakpoint('lg')`
    margin-top: -120px;
    border-radius: 8px 8px 0px 0px;
    width: 800px;
    `}
`;

const DescriptionWrapperInner = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${breakpoint('md')`
    width: 500px;
`};
`;

const HowItWorks = styled.div`
  font-size: 20px;
  font-family: Avenir medium;
  width: 100%;
  line-height: 22px;
  margin-top: 38px;
  ${breakpoint('md')`
    font-size:24px;
    line-height: 46px;
`}
`;
const StepsWrapper = styled.div`
  margin-top: 28px;
  ${breakpoint('md')`
    font-size:24px;
    line-height: 46px;
    margin-top: 28px;
`}
`;

const FooterContainer = styled.div`
  background-color: #f7f7f7;
`;
// ********************************* */

const Background = styled.div`
  background-color: #e5e5e5;
  ${breakpoint('md')`
    background-color: #E5E5E5;
`}
`;

const getSteps = (prismicData) => {
  const { body } = prismicData;
  const { items } = body[0];
  const steps = items.map((item) => ({ content: item.content, title: item.title }));
  return steps;
};

const ReferAFriendTemplate = ({ data, lang, pathName }) => {
  const { prismicReferAFriendPage } = data;
  const { hero_image, how_it_works_image } = prismicReferAFriendPage.data;
  const headerImage = getImage(hero_image);
  const bgImage = convertToBgImage(headerImage);
  const {
    copy_link_instructions,
    how_it_works_title,
    page_title,
    subtitles,
    small_description,
    disclaimer,
  } = prismicReferAFriendPage.data;
  const stepsData = getSteps(prismicReferAFriendPage.data);
  const steps = stepsData.map(({ content, title }, index) => (
    <Step content={content} title={title} index={index} key={title} />
  ));

  return (
    <Background style={{ backgroundColor: '#F0F0F0' }}>
      <Seo lang={lang} pathname={pathName} />
      <Header />
      <Wrapper id="rafPageWrapper">
        <BackgroundImage
          Tag="div"
          {...bgImage}
          style={{ backgroundPosition: 'center top', zIndex: 0 }}
        >
          <RafHeader id="header">
            <Beam />
            <PageTitleWrapper id="rafPage_title">
              <Title id="rafBitTitle">{page_title}</Title>
              <Subtitles content={subtitles} />
            </PageTitleWrapper>
          </RafHeader>
        </BackgroundImage>
        <Beam />
        <ContentWrapper id="contentWrapper">
          <DescriptionWrapper id="rafDescriptionWrapper">
            <DescriptionWrapperInner id="rafDescriptionWrapper">
              <Beam />
              <Description>{small_description}</Description>
              <LinkCopier copyMessage="COPY" instructions={copy_link_instructions} />
              <HowItWorks id="rafHowItWorks">
                <div>{how_it_works_title}</div>
                <GatsbyImage
                  imgStyle={{ borderRadius: '8px' }}
                  style={{ marginTop: '8px' }}
                  id="logo-image"
                  image={how_it_works_image.gatsbyImageData}
                  alt={how_it_works_image.alt || 'how_it_works_image'}
                />
              </HowItWorks>
              <StepsWrapper>{steps}</StepsWrapper>
              <Disclaimer>{disclaimer}</Disclaimer>
              <Beam />
            </DescriptionWrapperInner>
          </DescriptionWrapper>
        </ContentWrapper>
      </Wrapper>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </Background>
  );
};

const Component = withPrivateRoute(ReferAFriendTemplate);

export default withPrismicPreview(Component);

ReferAFriendTemplate.defaultProps = {
  data: { prismicSignUpLayout: {}, prismicCreateAccountPage: {} },
};

ReferAFriendTemplate.propTypes = {
  data: PropTypes.shape({
    /* eslint-disable react/forbid-prop-types */
    prismicSignUpLayout: PropTypes.object,
    prismicCreateAccountPage: PropTypes.object,
  }),
};

export const query = graphql`
  query ($lang: String!) {
    prismicReferAFriendPage(lang: { eq: $lang }) {
      _previewable
      data {
        page_title
        small_description
        copy_link_instructions
        subtitles
        how_it_works_title
        disclaimer
        how_it_works_image {
          alt
          gatsbyImageData(width: 488, height: 184)
        }
        hero_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicReferAFriendPageDataBodyStep {
            id
            items {
              content
              title
            }
          }
        }
      }
    }
  }
`;
