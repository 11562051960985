import styled from 'styled-components';
import COLORS from '../../../themes/colors';
import { breakpoint } from '../../../themes/breakpoints';

export const TrackItemWrapper = styled.div`
  padding: 16px;
  display: flex;
  gap: 12px;
  color: ${COLORS.lightishGrey};
  font-family: 'Avenir roman';

  &:hover {
    background-color: ${COLORS.lightBlack};
    border-radius: 16px;
    cursor: pointer;
  }
`;

export const TrackNumber = styled.div`
  font-family: 'Avenir heavy';
  font-size: 48px;
  font-weight: 800;
  flex: 0 0 60px;
  text-align: left;
  color: ${COLORS.greyLetter};
`;

export const TrackInfoContiner = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding-left: 0;

  ${breakpoint('md')`
    padding-left: 16px;
  `}
`;

export const TrackTitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TrackTitle = styled.div`
  font-size: 16px;
  font-weight: 800;
`;

export const TrackDuration = styled.div`
  border-radius: 4px;
  background-color: ${COLORS.darkGrey};
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
`;

export const TrackMusic = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.lightGreyAE};
  display: flex;
  gap: 8px;

  & .track-music-icon {
    margin-top: 2px;
  }
`;

export const TrackMoves = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const TrackPresenter = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.lightGreyAE};
`;
