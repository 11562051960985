/* eslint-disable @typescript-eslint/naming-convention */
import styled from 'styled-components';
import { breakpoint } from '../../../themes/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.darkGrey};
  width: 100%;

  ${breakpoint('md')`
    margin-top: 0;
    margin-bottom: 0;
  `}

  & img {
    margin: 0 auto;
  }

  & .submit-button {
    margin: 0 auto;
    width: 320px;
    background-color: ${(props) => props.theme.colors.darkGrey} !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
`;

export const Title = styled.h2`
  font-size: 40px;
  margin-bottom: 24px;
  margin-top: 0;
  font-family: 'Trade Gothic';
  text-transform: uppercase;
  letter-spacing: -0.03em;
  ${breakpoint('md')`
    font-size: 60px;
    line-height: 70px;
    width: 100%;
    text-align:center;
`}
`;

export const SubTitle = styled.h4`
  font-size: 20px;
  font-family: 'Trade Gothic';
`;

export const SuccessMessage = styled.p`
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 22px;
  margin-right: auto;
  margin-left: auto;
`;

export const WelcomeMessage = styled.p`
  font-size: 16px;
  margin-bottom: 70px;
  line-height: 22px;
  margin-right: auto;
  margin-left: auto;
`;

export const ProcessingMessage = styled.div`
  font-size: 16px;
  margin-bottom: 75px;
  line-height: 20px;
  width: 80%;
  ${breakpoint('md')`
    font-size: 24px;
    margin-bottom: 75px;
    line-height: 32px;
`}
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const Logo = styled.div`
  margin-bottom: 20px;
  text-align: center;

  ${breakpoint('md')`
    display: block;
    margin-bottom:45px;
  `}
`;

export const ButtonWrapper = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
  ${breakpoint('md')`
    width: auto;
    padding: 0;
    & .MuiButton-root {
      margin: 10px 1px;
    }
  `}
`;

export const SuccessTitle = styled.h2`
  font-size: 24px;
  font-family: 'Avenir heavy';
  font-weight: bold;
  line-height: 32.78px;
`;

export const DescriptionMessage = styled.p`
  font-size: 32px;
  font-family: 'Avenir heavy';
  margin-bottom: 62px;
  line-height: 43.71px;
  margin-right: auto;
  margin-left: auto;
`;

export const DescriptionMessageUpdate = styled.p`
  font-size: 32px;
  font-family: 'Avenir heavy';
  line-height: 43.71px;
  margin-right: auto;
  margin-left: auto;
  ${breakpoint('md')`
    width:100%;
  `};
`;

export const PartnerMessage = styled.p`
  font-size: 14px;
  font-family: 'Avenir';
  line-height: 18px;
  margin-top: 21.13px;
  margin-bottom: 52px;
  margin-right: auto;
  margin-left: auto;
`;

export const SuccessLogo = styled.div`
  text-align: center;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;
