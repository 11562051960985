import styled from 'styled-components';
import { breakpoint } from '@themes';
import COLORS from '../../../themes/colors';

interface VideoGridTabWrapperProps {
  displaySpotlightRibbon: boolean;
}

export const VideoGridTabWrapper = styled.div<VideoGridTabWrapperProps>`
  width: 100%;
  z-index: 2;
  padding-bottom: 24px;
  background: ${COLORS.black};
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  margin-top: 0;

  ${breakpoint('sm')`
    margin-top: -130px;
  `}

  @media (min-width: 600px) {
    margin-top: ${(props) => (props.displaySpotlightRibbon ? '-50px' : '-80px')};
  }

  ${breakpoint('md')`
    margin-top: ${(props) => (props.displaySpotlightRibbon ? '-20px' : '-90px')};
  `}

  ${breakpoint('lg')`
    margin-top: ${(props) => (props.displaySpotlightRibbon ? '-50px' : '-90px')};
  `}

  ${breakpoint('xl')`
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: -200px;
  `}

  ${breakpoint('xxl')`
    margin-top: ${(props) => (props.displaySpotlightRibbon ? '-160px' : '-200px')};
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  z-index: inherit;

  @media (min-width: 600px) {
    margin-left: 32px;
    margin-right: 32px;
  }

  ${breakpoint('lg')`
    margin-left: 80px;
    margin-right: 80px;
  `}
`;

export const Title = styled.h4`
  color: ${(props) => props.theme.colors.lightishGrey};
  opacity: ${(props) => (props.isActive ? '1' : '0.5')};
  cursor: ${(props) => (props.isActive ? 'inherit' : 'pointer')};
  font-size: 16px;
  line-height: 25px;
  font-family: 'Avenir heavy';
  width: 100%;

  ${breakpoint('sm')`
    font-size: 18px;
  `}

  ${breakpoint('lg')`
    line-height: 33px;
  `}
`;

export const Link = styled.a`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;
  font-weight: 500;
  font-family: Avenir;
`;

export const BannerText = styled.a`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.white};
  font-weight: 500;
  font-family: Avenir;
  margin-right: 12px;
`;

export const FixedBannerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 44px;
  background-color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
`;
