import React, { FC, useState } from 'react';
import { Dialog, Link } from '@components/atoms';
import { Carousel, HomeSection, VideoItem } from '@components/molecules';
import { ArrowDown, X } from 'phosphor-react';

// TODO: Pull data from Prismic when they're ready.

const HomeGetStarted: FC = () => {
  const [active, setActive] = useState(1);
  const [showSkipSeriesDialog, setShowSkipSeriesDialog] = useState(false);
  const [skipSeries, setSkipSeries] = useState(false);

  if (skipSeries) return null;

  return (
    <>
      <HomeSection
        title="Get Started"
        description={
          <div className="space-y-4">
            <p>
              It is a long established fact that a reader will be distracted by the readable content
              of a page when looking at its layout. The point of using Lorem Ipsum.
            </p>
            <Link to="/" className="block font-avenir-heavy uppercase">
              Download PDF
              <ArrowDown
                color="currentColor"
                weight="bold"
                className="w-3 h-3 inline-block ml-1 mb-0.5"
              />
            </Link>
          </div>
        }
        onSkip={() => setShowSkipSeriesDialog(true)}
      >
        <Carousel value={active} onChange={(v) => setActive(v)} slides={2}>
          <Carousel.Item>
            <VideoItem
              order={1}
              active={active === 0}
              name="Getting Started"
              description="BODYPUMP is a barbell workout for everyone that likes to read lorem ipsum and even works for longer paragraphs."
            />
          </Carousel.Item>
          <Carousel.Item>
            <VideoItem
              order={2}
              active={active === 1}
              name="What is BODYPUMP?"
              description="BODYPUMP is a barbell workout for everyone that likes to read lorem ipsum."
            />
          </Carousel.Item>
          <Carousel.Item>
            <VideoItem
              order={3}
              active={active === 2}
              name="What is BODYPUMP?"
              description="BODYPUMP is a barbell workout for everyone that likes to read lorem ipsum."
            />
          </Carousel.Item>
          <Carousel.Item>
            <VideoItem
              order={4}
              active={active === 3}
              name="What is BODYPUMP?"
              description="BODYPUMP is a barbell workout for everyone that likes to read lorem ipsum."
            />
          </Carousel.Item>
          <Carousel.Item>
            <VideoItem
              order={5}
              active={active === 4}
              name="What is BODYPUMP?"
              description="BODYPUMP is a barbell workout for everyone that likes to read lorem ipsum."
            />
          </Carousel.Item>
        </Carousel>
      </HomeSection>

      <Dialog show={showSkipSeriesDialog} onClose={() => setShowSkipSeriesDialog(false)}>
        <Dialog.Icon icon={X} />

        <Dialog.Title>Skip Series</Dialog.Title>

        <Dialog.Description>
          Skipping this series will remove it from your homepage feed.
        </Dialog.Description>

        <Dialog.Footer>
          <Dialog.PrimaryButton
            onClick={() => {
              setSkipSeries(true);
              setShowSkipSeriesDialog(false);
            }}
          >
            Yes, skip series
          </Dialog.PrimaryButton>

          <Dialog.SecondaryButton onClick={() => setShowSkipSeriesDialog(false)}>
            No, keep it
          </Dialog.SecondaryButton>
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

export default HomeGetStarted;
