import styled from 'styled-components';

export const Container = styled.div`
  max-width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TermsCondition = styled.div`
  text-align: center;
  font-family: 'Avenir roman';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin: 24px 0;
  color: ${(props) => props.theme.colors.greyLetter};
`;

export const AccountPageLink = styled.a`
  text-decoration: underline;
`;
