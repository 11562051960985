import React from 'react';
import PropTypes from 'prop-types';

import { Button as MUIButton, CircularProgress } from '@mui/material';

import COLORS from '../../../themes/colors';

const Button = ({ children, ...rest }) => {
  const { disabled, loading, borderRadius } = rest;
  const styleObj = {
    width: '318px',
    height: '48px',
    color: COLORS.white,
    backgroundColor: disabled ? COLORS.silver : COLORS.darkGrey,
    borderRadius: borderRadius || 0,
    boxShadow: 'none',
    lineHeight: '19px',
    fontFamily: 'Avenir medium',
    letterSpacing: '0.08em',
  };
  return (
    <MUIButton {...rest} disabled={disabled || loading} style={styleObj}>
      {loading ? (
        <CircularProgress style={{ width: 21, height: 21, color: COLORS.white }} />
      ) : (
        children
      )}
    </MUIButton>
  );
};

Button.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Button;
