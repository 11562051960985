import React, { FC, useContext, useEffect } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, PageProps, navigate } from 'gatsby';
import styled from 'styled-components';

import { withPrivateRoute, Layout } from '@components/molecules';
import SliceZone from '@components/sliceZone';
import { userContext } from '@context';
import { TIERS } from '@constants';
import PageName from '../../types/pageName';

import Container from './style';

const HomepageWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
`;

const HomePersonalise: FC<PageProps<Queries.GetNewHomepageDataQuery>> = ({ data }) => {
  const { tier, vimeoUserId, email, familyName, givenName, lmodSubscription } =
    useContext(userContext);

  useEffect(() => {
    if (tier === TIERS.BASE) {
      navigate('/explore');
    }
  }, [tier]);

  const layoutScripts = [
    {
      type: 'text/javascript',
      innerHTML: `var _cio = _cio || [];
              (function () {
                if (!document.getElementById('cio-tracker')) {
                var a, b, c;
                a = function (f) {
                  return function () {
                    _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
                  };
                };
                b = ['load', 'identify', 'sidentify', 'track', 'page'];
                for (c = 0; c < b.length; c++) {
                  _cio[b[c]] = a(b[c]);
                }
                var t = document.createElement('script'),
                  s = document.getElementsByTagName('script')[0];
                t.async = true;
                t.id = 'cio-tracker';
                t.setAttribute('data-site-id', '${process.env.GATSBY_RT_09_11_2023_CUSTOMERIO_SITE_ID}');
                t.setAttribute('data-use-in-app', 'true');
                t.src = 'https://assets.customer.io/assets/track.js';
                s.parentNode.insertBefore(t, s);
              }
              })();
              _cio.identify({
                id: '${vimeoUserId}',
                email: '${email}',
                first_name: '${givenName}',
                last_name: '${familyName}',
                plan_name: '${lmodSubscription?.product?.name}'
              });
            `,
    },
  ];

  return (
    <Layout
      scripts={layoutScripts}
      metaData={{
        title: data.prismicNewWebHomepage?.data?.meta_title,
        description: data.prismicNewWebHomepage?.data?.meta_description,
      }}
    >
      <Container>
        <HomepageWrapper>
          <SliceZone
            slices={data.prismicNewWebHomepage?.data?.body}
            listPrograms={data?.lesMillsPlusBackend?.listPrograms?.items}
            pageName={PageName.HOME}
          />
        </HomepageWrapper>
      </Container>
    </Layout>
  );
};

export default withPrismicPreview(withPrivateRoute(HomePersonalise));

export const query = graphql`
  query GetNewHomepageData($lang: String!) {
    prismicNewWebHomepage(lang: { eq: $lang }) {
      data {
        meta_description
        meta_title
        body {
          ... on PrismicNewWebHomepageDataBodyPrograms {
            id
            slice_type
            slice_label
            primary {
              title
              view_all_text
              view_all_link
              is_tall
            }
          }
          ... on PrismicNewWebHomepageDataBodyWorkoutVideoCarousel {
            id
            slice_type
            slice_label
            primary {
              title
              view_all_text
              view_all_link
            }
            items {
              video_id
            }
          }
          ... on PrismicNewWebHomepageDataBodyAlbumsCarousel {
            id
            slice_type
            slice_label
            primary {
              title
              view_all_text
              view_all_link
              aspect_ratio
              small_card
            }
            items {
              label
              title
              topic
              description
              detail_link
              duration_min
              duration_sec
              background_image {
                url
              }
              equipment_bench_step
              equipment_foam_roller
              equipment_massage_ball
              equipment_mat
              equipment_mini_trampolim
              equipment_resistance_band
              equipment_stationary_bike
              equipment_weights
            }
          }
          ... on PrismicNewWebHomepageDataBodyVideoSlim {
            id
            slice_type
            slice_label
            items {
              title
              type
              view_all_text
              view_all_link
            }
          }
          ... on PrismicNewWebHomepageDataBodyHomepageHeroCarousel {
            id
            slice_type
            slice_label
            primary {
              title
              view_all_text
              view_all_link
              aspect_ratio
            }
            items {
              prismic_content
              label
              title
              topic
              description
              detail_link
              duration_min
              duration_sec
              background_image {
                url
              }
              target_user
              equipment_bench_step
              equipment_foam_roller
              equipment_massage_ball
              equipment_mat
              equipment_mini_trampolim
              equipment_resistance_band
              equipment_stationary_bike
              equipment_weights
            }
          }
          ... on PrismicNewWebHomepageDataBodyHomepageFooter {
            id
            slice_type
            slice_label
            primary {
              footer_content
              footer_link_text
              footer_link
            }
          }
        }
      }
    }
  }
`;
