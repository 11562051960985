import { Link } from '@components/atoms';
import React, { FC } from 'react';

interface Props {
  icon?: any;
  title?: string;
  to: string;
}

const NavLink: FC<Props> = ({ to, icon, title }) => {
  const classes =
    'flex space-x-2 font-avenir-heavy uppercase text-netural-400 text-14 pl-2 border-l-2 border-transparent hover:border-grey transition-colors duration-300 ease-out w-full';
  const content = (
    <>
      <span className="flex-grow">{title}</span>

      {icon}
    </>
  );

  return (
    <Link to={to} className={classes}>
      {content}
    </Link>
  );
};

export default NavLink;
