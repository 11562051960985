import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Button } from '@components/atoms';

const ProductCard = ({ product, backgroundColor, textColor }) => (
  <div style={{ backgroundColor }} className="h-full p-4 shadow-lg flex flex-col mx-2">
    <div className="flex-grow">
      <div className="h-auto w-full">
        <GatsbyImage
          className="object-cover object-center w-full h-auto"
          image={product.image.gatsbyImageData}
          alt={product.image.alt ? product.image.alt : ''}
        />
      </div>

      <h3
        className={`uppercase font-trade-gothic text-16 text-center py-4 ${
          textColor === 'Dark' ? '' : 'text-white'
        }`}
      >
        {product.name}
      </h3>
    </div>
    <div>
      <Button isBlock variant="secondary" size="medium" href={product.url.url}>
        {product.call_to_action}
      </Button>
    </div>
  </div>
);

export default ProductCard;
