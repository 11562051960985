/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import { breakpoint } from '@themes';
import { Link } from '@components/atoms';

export const HomepageFooterWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  display: none;

  ${breakpoint('sm')`
    display: block;
  `}
`;

export const HomepageFooterContent = styled.div`
  padding: 29px;
  height: 80px;
  flex: 1;
  font-size: 16px;
  font-family: 'Avenir roman';
  text-align: center;
  line-height: 22px;
  color: ${(props) => props.theme.colors.lightishGrey};
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;

  a {
    text-decoration: underline;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
`;
