import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../themes/breakpoints';

const SubtitleWrapper = styled.div`
  font-family: 'Trade Gothic';
  letter-spacing: -0.02em;
  margin-right: auto;
  font-weight: 700;
  width: auto;
  text-transform: uppercase;
  text-align: center;
  ${breakpoint('md')`
    font-size:40px;
    line-height: 66px;
`};
`;
const MobileSubtitle = styled.div`
  font-size: 32px;
  line-height: 34px;
  display: block;
  ${breakpoint('md')`
    display:none;
`}
`;

const Bullet = styled.div`
  font-size: 20px;
  line-height: 66px;
  display: none;
  ${breakpoint('md')`
    display: block;
    width: 24px;
`}
`;
const DesktopSubtitle = styled.div`
  display: none;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  ${breakpoint('md')`
    display: flex;
`}
`;

const Subtitles = ({ content }) => {
  const [get, give] = content.split(',');
  return (
    <SubtitleWrapper id="rafSubtitleWrapper">
      <DesktopSubtitle id="rafDesktopSubtitle">
        <div>{give}</div>
        <Bullet> &bull; </Bullet>
        <div>{get}</div>
      </DesktopSubtitle>
      <MobileSubtitle id="rafMobileSubtitleGive">{give}</MobileSubtitle>
      <MobileSubtitle id="rafMobileSubtitleGet">{get}</MobileSubtitle>
    </SubtitleWrapper>
  );
};

export default Subtitles;
