import React, { useState, useEffect } from 'react';
import { Chip } from '@lesmills-international/components';
import Select from '@mui/material/Select';
import ListItem from '@mui/material/ListItem';
import { ReactComponent as SortIcon } from '@assets/icons/sort.svg';
import { ReactComponent as TickIcon } from '@assets/icons/tick.svg';
import { SORTING } from '@constants';
import styled from 'styled-components';
import COLORS from '../../../themes/colors';

interface SingleSelectDropdownProps {
  options: string[];
  label: string;
  selectedValue: string;
  onChange: (selected: string) => void;
}

const DropdownWrapper = styled.div`
  padding: 8px 0;
  white-space: nowrap;
  .MuiMenu-root {
    /* Hide the original switch icon */
    width: 0;
    height: 0;
  }
`;

const StyledTickIcon = styled(TickIcon)`
  margin-left: auto;
  margin-bottom: 3px;
`;

const SingleSelectDropdown: React.FC<SingleSelectDropdownProps> = ({
  options,
  label,
  selectedValue,
  onChange,
}) => {
  const [open, setOpen] = useState(false);

  const handleSelectChange = (value: string) => {
    onChange(value);
  };

  const toggleDropdown = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Check if the dropdown is open and the user has scrolled
      if (open) {
        setOpen(false);
      }
    };

    // Add the scroll event listener to the document
    document.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [open]);

  const styleObj = {
    position: 'relative',
    color: COLORS.lightishGrey,
    backgroundColor: COLORS.greyOpacity06,
    lineHeight: '24px',
    fontFamily: 'Avenir',
    fontWeight: 400,
    fontSize: '16px',
    width: '160px',
    backdropFilter: 'blur(80px)',
    borderRadius: '8px',
    border: `1px solid ${COLORS.darkGrey}`,
  };

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  return (
    <DropdownWrapper>
      <Chip
        theme="dark"
        active={!!selectedValue && selectedValue !== SORTING.DEF}
        label={!selectedValue || selectedValue === SORTING.DEF ? label : selectedValue}
        leftIcon={<SortIcon />}
        onClick={toggleDropdown}
      />
      {open && (
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={selectedValue}
          onChange={handleSelectChange}
          renderValue={() => null} // Hide the selected values in the dropdown
          style={{
            top: '-38px',
            right: selectedValue === SORTING.DEF ? '20px' : '-24px',
            visibility: 'hidden',
          }}
          MenuProps={{
            PaperProps: {
              style: styleObj,
            },
          }}
        >
          {options.map((option) => (
            <ListItem
              key={option}
              style={{ cursor: 'pointer' }}
              onClick={() => handleSelectChange(option)}
            >
              {option}
              {selectedValue === option && <StyledTickIcon />}
            </ListItem>
          ))}
        </Select>
      )}
    </DropdownWrapper>
  );
};

export default SingleSelectDropdown;
