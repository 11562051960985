import styled from 'styled-components';
import COLORS from '@themes/colors';

export const SearchBarWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
`;

export const Input = styled.input`
  height: 40px;
  width: 100%;
  padding: 8px 12px 8px 12px;
  gap: 10px;
  margin-left: 1rem;
  background-color: transparent;
  color: ${COLORS.lightishGrey};
  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${COLORS.lightGreyAE};
    padding-left: 2px;
  }
`;

export const SearchIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  padding-left: 15px;
  padding-top: 7px;
  color: ${COLORS.lightishGrey};
  transform: scale(1.6);
`;

export const CloseButton = styled.button`
  height: 24px;
  width: 24px;
  cursor: pointer;
  transform: scale(0.8);
  margin-right: 12px;
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  color: ${COLORS.lightGreyAE};
`;
