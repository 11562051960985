import styled from 'styled-components';

import { breakpoint } from '../../../themes/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 100px auto;
  background-color: ${(props) => props.theme.colors.white};

  ${breakpoint('md')`
    margin: 0 auto;
  `}

  & .payment-title {
    margin-bottom: 17px;
  }

  & .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0 0 12px 0;
  }

  & .submit-button {
    margin: 10px 1px;
  }

  & .submit-button:hover {
    background-color: ${(props) => props.theme.colors.lightBlack} !important;
  }
`;

export const Logo = styled.div`
  margin-bottom: 12px;
  text-align: center;
  display: none;
  ${breakpoint('md')`
    display: block;
  `}
  ${breakpoint('lg')`
    display: none;
  `}
`;

export const BackBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  left: 16px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.colors.darkGreyOpacity01};
  transition: border-color 0.15s;
  transition-timing-function: ease-out;

  &:hover {
    border-color: ${(props) => props.theme.colors.darkGrey};
  }

  ${breakpoint('md')`
    top: 16px;
    left: 22px;
  `}
`;

export const PayTitle = styled.h2`
  font-size: 18px;
  line-height: 24px;
  font-family: 'Avenir heavy';
  margin-bottom: 16px;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  text-align: center;
  font-family: 'Avenir medium';
`;

export const CancelTip = styled.span`
  font-family: 'Avenir heavy';
`;

export const TermsCondition = styled.div`
  font-size: 10px;
  line-height: 14px;
  margin-top: 18px;
  text-align: left;
  margin-top: 16px;
  font-family: 'Avenir roman';
  color: ${(props) => props.theme.colors.darkGrey};
  & a {
    text-decoration: underline;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  & .MuiButton-root {
    margin: 0 1px;
  }
  ${breakpoint('md')`
    width: auto;
    padding: 0;
    & .MuiButton-root {
      margin: 10px 1px;
    }
  `}
`;

// rollover
export const RolloverWrapper = styled.div`
  margin-top: 13px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGreyB0};
  font-family: 'Avenir roman';
  color: ${(props) => props.theme.colors.darkGrey};
  padding-bottom: 18px;

  & .form-field-helper {
    color: ${(props) => props.theme.colors.errorRed};
    font-size: 12px;
    line-height: 18px;
    text-align: left;
  }
`;

export const RolloverLabel = styled.p`
  font-size: 12px;
  line-height: 17px;
`;

export const RolloverDescription = styled.p`
  font-size: 10px;
  line-height: 14px;
`;

export const PaymentDetailWrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.borderGrey};
  width: calc(100%-32px); /* twice the value of the parent's padding*/
  margin-left: -16px; /* -1 * parent's padding*/
  margin-right: -16px; /* -1 * parent's padding*/
`;
