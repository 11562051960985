import { CaretRight } from 'phosphor-react';
import React, { FC } from 'react';

interface Props {
  icon?: any;
  title?: string;
  onClick: () => void;
}

const NavButton: FC<Props> = ({ onClick, icon, title }) => {
  const classes =
    'flex space-x-2 font-avenir-heavy uppercase text-grey text-14 pl-2 border-l-2 border-transparent hover:border-grey transition-colors duration-300 ease-out w-full text-left';
  const content = (
    <>
      <span className="flex-grow">{title}</span>

      {icon || <CaretRight color="currentColor" size={20} />}
    </>
  );

  return (
    <button onClick={onClick} type="button" className={classes}>
      {content}
    </button>
  );
};

export default NavButton;
