import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { BackgroundImage } from '@components/atoms';
import { ProductCard } from '@components/molecules';
import './styles.css';

const ShopProductSlice = ({ content }) => {
  const sliderResponsive = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3.2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1.2,
      },
    },
  ];

  return (
    <div className="flex justify-center w-full">
      <div className="lg:pt-4 lg:px-4 w-full max-w-screen-3xl">
        <BackgroundImage image={content.primary.background_image}>
          <div
            className="absolute w-full h-full md:hidden"
            style={{ backgroundColor: content.primary.theme_color }}
          />
          <div>
            <div className="px-8 pt-12 lg:pt-56 lg:px-32">
              <img
                className="w-32 opacity-50"
                src={content.primary.logo.url}
                alt={content.primary.logo.alt}
              />
              <div
                className={`leading-none  opacity-95 ${
                  content.primary.text_color === 'Dark' ? '' : 'text-white'
                }`}
              >
                <h2 className="uppercase font-trade-gothic text-56">{content.primary.heading}</h2>
                <div
                  className="leading-normal pt-4 w-1/2 font-avenir hidden md:block"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: content.primary.lead.html }}
                />
              </div>
            </div>
            <div className="product-slider py-8 md:pt-16 md:pb-12 lg:px-16">
              <Slider
                dots={false}
                infinite={false}
                arrows={false}
                speed={500}
                swipeToSlide
                slidesToShow={5}
                responsive={sliderResponsive}
              >
                {content.items.map((item, index) => (
                  <ProductCard
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    product={item.product_link.document.data}
                    backgroundColor={content.primary.theme_color}
                    textColor={content.primary.text_color}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </BackgroundImage>
      </div>
    </div>
  );
};

export default ShopProductSlice;
