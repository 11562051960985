import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { breakpoint } from '@themes';

export const VideoCarouselSliceWrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  background: none;
  gap: 24px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  ${breakpoint('lg')`
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 32px;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 16px;

  ${breakpoint('sm')`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${breakpoint('lg')`
    margin-left: 80px;
    margin-right: 80px;
  `}
`;

export const Title = styled.h4`
  color: ${(props) => props.theme.colors.lightishGrey};
  font-size: 18px;
  line-height: 25px;
  font-family: 'Avenir heavy';

  ${breakpoint('lg')`
    font-size: 24px;
    line-height: 33px;
  `}
`;

export const ViewAllLink = styled.a`
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;

  ${breakpoint('lg')`
    font-size: 18px;
    line-height: 21px;
  `}
`;

export const CarouselWrapper = styled.div`
  & .swiper-wrapper {
    align-items: stretch !important;
  }

  & .swiper {
    padding-left: 16px;
    padding-right: 16px;

    ${breakpoint('sm')`
      padding-left: 32px;
      padding-right: 32px;
    `}

    ${breakpoint('lg')`
      padding-left: 80px;
      padding-right: 80px;
    `}
  }

  & .swiper-slide {
    height: auto;
  }

  & .videoCard {
    height: 100%;
  }
`;
