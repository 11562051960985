import styled from 'styled-components';
import { themes } from '@lesmills-international/components';
import { breakpoint } from '../../../themes/breakpoints';

export const DialogButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const DialogTextButton = styled.button`
  font-family: 'Avenir medium';
  text-decoration: underline;
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentPrimary};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const SurveyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CancellationSurvey = styled.iframe`
  ${breakpoint('lg')`
  width: 100%;
  height: 600px;
  `}

  width: 105%;
  height: 65vh;
  padding-bottom: 0;
`;
