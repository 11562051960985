/* eslint-disable @typescript-eslint/naming-convention */
import styled from 'styled-components';
import { breakpoint } from '../../../themes/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.darkGrey};
  width: 100%;

  ${breakpoint('md')`
    margin-top: 0;
    margin-bottom: 0;
  `}

  & img {
    margin: 0 auto;
  }

  & .submit-button {
    margin: 0 auto;
    width: 320px;
    background-color: ${(props) => props.theme.colors.darkGrey} !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
  ${breakpoint('md')`
    width: auto;
    padding: 0;
    & .MuiButton-root {
      margin: 10px 1px;
    }
  `}
`;

export const ErrorTypeTitle = styled.h1`
  font-size: 36px;
  font-family: 'Avenir heavy';
  line-height: 49.18px;
  witdh: 320px;
`;

export const DescriptionMessage = styled.p`
  font-size: 14px;
  font-family: 'Avenir';
  margin-top: 23px;
  margin-bottom: 53px;
  line-height: 18px;
  margin-right: auto;
  margin-left: auto;
  white-space: pre-line;
`;

export const ErrorImageLogo = styled.div`
  text-align: center;
  width: 100%
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 42.75px;
`;

export const Img = styled.img`
  margin: 0 auto;
`;
